<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.57 35.57">
    <defs>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M35.57,6.84c0-1.81-.72-3.55-2-4.84-1.28-1.28-3.02-2-4.84-2H6.84c-1.81,0-3.55,.72-4.84,2C.72,3.29,0,5.03,0,6.84V28.73c0,1.81,.72,3.55,2,4.84,1.28,1.28,3.02,2,4.84,2H28.73c1.81,0,3.55-.72,4.84-2,1.28-1.28,2-3.02,2-4.84V6.84ZM18.02,23.26H6.84c-.76,0-1.37,.61-1.37,1.37s.61,1.37,1.37,1.37h11.18c.56,1.59,2.08,2.74,3.87,2.74s3.31-1.14,3.87-2.74h2.97c.76,0,1.37-.61,1.37-1.37s-.61-1.37-1.37-1.37h-2.97c-.56-1.59-2.08-2.74-3.87-2.74s-3.31,1.14-3.87,2.74ZM9.81,9.58c.56-1.59,2.08-2.74,3.87-2.74s3.31,1.14,3.87,2.74h11.18c.76,0,1.37,.61,1.37,1.37s-.61,1.37-1.37,1.37h-11.18c-.56,1.59-2.08,2.74-3.87,2.74s-3.31-1.14-3.87-2.74h-2.97c-.76,0-1.37-.61-1.37-1.37s.61-1.37,1.37-1.37h2.97Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>        
.cls-1 {
  fill: var(--lab-carbon-fiber);
  fill-rule: evenodd;
}
.dark-layout .cls-1  {
  fill: #C494B1;
}
</style>
