<template>
  <div :class="className">
    <svg :id="$store.state.appConfig.layout.skin" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <defs>
      </defs>
      <g id="Layer_2-2" data-name="Layer 2">
        <path class="cls-1" d="M15,30C6.73,30,0,23.27,0,15,0,6.73,6.73,0,15,0s15,6.73,15,15c0,8.27-6.73,15-15,15Zm0-28c-7.17,0-13,5.83-13,13s5.83,13,13,13,13-5.83,13-13S22.17,2,15,2Z"/>
        <path class="cls-1" d="M6.97,16.74v-3.49H23.03v3.49H6.97Zm6.17,6.1V7.16h3.73v15.69h-3.73Z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    className: {
      default: 'normal',
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.normal {
  #dark .cls-1, #dark .cls-2 {
    fill: var(--lab-lago-blue-color);
  }
  #light .cls-1, #light .cls-2 {
    fill: var(--lab-carbon-fiber);
  }
}

.encyclopedia {
  #dark .cls-1, #dark .cls-2 {
    fill: var(--lab-g25-supplementary-color);
  }
  #light .cls-1, #light .cls-2 {
    fill: var(--lab-stieglitz-silver);
  }
}
</style>
