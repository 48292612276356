const mixedModeHelper = { 
  /* eslint-disable */
  ResolveAncientCoordinates(ancientResultList) {
    let result = ancientResultList.threeWayResult.map(x => x.firstPop[1]);
    result = result.concat(ancientResultList.threeWayResult.map(x => x.secondPop[1]));
    result = result.concat(ancientResultList.threeWayResult.map(x => x.thirdPop[1]));

    result = result.concat(ancientResultList.twoWayResult.map(x => x.firstPop[1]));
    result = result.concat(ancientResultList.twoWayResult.map(x => x.secondPop[1]));

    return result;
  },

  OnMakeChartObj(threeWayData, isTwoWay) {
    let data = [];

    if (isTwoWay) {
      data.push({ value: threeWayData.firstPop[0], name: threeWayData.firstPop[1]});
      data.push({ value: threeWayData.secondPop[0], name: threeWayData.secondPop[1]});
    } else {
      data.push({ value: threeWayData.firstPop[0], name: threeWayData.firstPop[1]});
      data.push({ value: threeWayData.secondPop[0], name: threeWayData.secondPop[1]});
      data.push({ value: threeWayData.thirdPop[0], name: threeWayData.thirdPop[1]});
    }
    return [
      {
        name: 'Population',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ];
  },
  MixedModeRunAll(mixedModeResult) {
    const result = {};
    if (mixedModeResult.threeWayResult) {
      result.threeWayResult = this.MixedModeThreeWayConvert(mixedModeResult.threeWayResult, 50);
    }
    if (mixedModeResult.twoWayResult) {
      result.twoWayResult = this.MixedModeTwoWayConvert(mixedModeResult.twoWayResult, 50);
    }

    return result;
  },

  MixedModeThreeWayConvert(wayResult, popNumber) {
    const mixedModeResult = [];
    
    let threeWay = wayResult.trim().replace(/\r\n/g, '\n').replace(/\'/g, '').replace(/\</g, '&lt;').replace(/\>/g, '&gt;');

    threeWay = threeWay.replace(/[^\S\n]/g, "");
    threeWay = threeWay.replace(/\n+/g, "\n");
    threeWay = threeWay.replace(/\,+/g, "\,");
    threeWay = threeWay.split("\n");
   
    if (threeWay.length > 0) {
      if (threeWay[0].includes('3-PopulationApproximationList')) {
        threeWay.shift();
      }
    }

    threeWay.forEach((value, index) => {
      const popFit = value.split('@');
      const fit = popFit[1];

      const pops = popFit[0].split('+');
      
      const firstPop = pops[0].split('%');
      const secondPop = pops[1].split('%');
      const thirdPop = pops[2].split('%');
      firstPop[0] = Math.round(firstPop[0] * 100) / 100;
      secondPop[0] = Math.round(secondPop[0] * 100) / 100;
      thirdPop[0] = Math.round(thirdPop[0] * 100) / 100;

      firstPop[1] = firstPop[1];
      secondPop[1] = secondPop[1];
      thirdPop[1] = thirdPop[1];

      if (index < popNumber) {
        const wayData = {
          id: index,
          fit: fit,
          firstPop: firstPop,
          secondPop: secondPop,
          thirdPop: thirdPop,
        };

        wayData.chartObj = this.OnMakeChartObj(wayData, false);
        mixedModeResult.push(wayData);
      }
    });

    return mixedModeResult;
  },

  MixedModeTwoWayConvert(wayResult, popNumber) {
    const mixedModeResult = [];
    let twoWayResult = wayResult.trim().replace(/\r\n/g, '\n').replace(/\</g, '&lt;').replace(/\>/g, '&gt;');
    twoWayResult = twoWayResult.replace(/[^\S\n]/g, "");
    twoWayResult = twoWayResult.replace(/\n+/g, "\n");
    twoWayResult = twoWayResult.replace(/\,+/g, "\,");
    twoWayResult = twoWayResult.split("\n");
  
    if (twoWayResult.length > 0) {
      if (twoWayResult[0].includes('2-PopulationApproximationList')) {
        twoWayResult.shift();
      }
    }
    
    twoWayResult.forEach((value, index) => {
      const popFit = value.split('@');
      const fit = popFit[1];

      const pops = popFit[0].split('+');
      
      const firstPop = pops[0].split('%');
      const secondPop = pops[1].split('%');

      firstPop[0] = Math.round(firstPop[0] * 100) / 100;
      secondPop[0] = Math.round(secondPop[0] * 100) / 100;

      firstPop[1] = firstPop[1];
      secondPop[1] = secondPop[1];
        
      if (index < popNumber) {
        const wayData = {
          id: index,
          fit: fit,
          firstPop: firstPop,
          secondPop: secondPop,
        };

        wayData.chartObj = this.OnMakeChartObj(wayData, true);
        mixedModeResult.push(wayData);
      }
    });

    return mixedModeResult;
  },
}

export default mixedModeHelper