<template>
  <div class="lab-dropdown region">
    <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
      <span class="lab-dropdown-btn-value">{{ $store.state.ancestrybreakdown.numberOfGlobalnPop === 0 ? 'No limit' : $store.state.ancestrybreakdown.numberOfGlobalnPop + ' Pop'}}</span>
      <span class="lab-dropdown-btn-icon">
        <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/dropdown.svg'">
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
      <ul>
        <li @click="onChangeGlobalPop(3)">
          3
        </li>
        <li @click="onChangeGlobalPop(4)">
          4
        </li>
        <li @click="onChangeGlobalPop(5)">
          5
        </li>
        <li @click="onChangeGlobalPop(0)">
          No limit
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    runAncestryBreakDown: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isShowDropdown: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },

    onChangeGlobalPop(value) {
      if (this.$store.state.ancestrybreakdown.numberOfGlobalnPop !== value) {
        this.$store.commit('ancestrybreakdown/setNumberOfGlobalnPop', value);
        this.$store.commit('ancestrybreakdown/setSelectedRegion', this.$store.getters['ancestrybreakdown/getOrderSelectedRegion']);
        this.$store.commit('operation/setAncestryGeoJson', []);
        this.runAncestryBreakDown(this.$store.getters['ancestrybreakdown/getOrderSelectedRegion']);
      }
    },
  },
  computed: {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.lab-dropdown .lab-dropdown-content {
  width: 100%;
}
.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-lago-blue-color);
  border: 2px solid var(--lab-lago-blue-color);
}
</style>
