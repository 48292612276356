<template>
  <div class="main-g25">
    <div class="encyclopedia-container" v-if="selectedPopDetail === null">
      <b-row class="encyclopedia-padding mt-2 hidden-mobile">
        <b-col cols="12" xl="4" md="4">
          <div class="title">
            <h4>Encyclopedia</h4>
            <p>Meta and genetic informations of ancient and modern populations</p>
          </div>
        </b-col>
        <b-col cols="12" xl="8" md="8">
          <div>
            <encyclopedia-filter />
          </div>
        </b-col>
      </b-row>
      <encyclopedia-filter-mobile class="hidden-desktop"/>
      <div class="encyclopedia-body mt-2">
        <ancient-encyclopedia v-if="selectedModule === 'ANCIENT'"/>
        <modern-encyclopedia v-if="selectedModule === 'MODERN'"/>
        <ancient-individual-encyclopedia v-if="selectedModule === 'ANCIENT-INDIVIDUAL'"/>
      </div>
    </div>

    <div v-else>
      <encyclopedia-detail :popData="selectedPopDetail"/>
    </div>
  </div>
</template>

<script>
import AncientEncyclopedia from './modules/AncientEncyclopedia.vue';
import AncientIndividualEncyclopedia from './modules/AncientIndividualEncyclopedia.vue'
import ModernEncyclopedia from './modules/ModernEncyclopedia.vue';

import EncyclopediaFilter from './EncyclopediaFilter.vue';
import EncyclopediaFilterMobile from './EncyclopediaFilterMobile.vue';
import EncyclopediaDetail from './modules/EncyclopediaDetail.vue';

export default {
  directives: {
  },
  components: {
    EncyclopediaFilter,
    EncyclopediaFilterMobile,
    AncientEncyclopedia,
    AncientIndividualEncyclopedia,
    ModernEncyclopedia,
    EncyclopediaDetail,
  },
  data() {
    return {
    }
  },
  methods: {
    onLoadFarmerHGsData() {
      if (!this.$store.state.farmerhgs.farmerAndHGsSourceData.length) {
        this.$store.dispatch('farmerhgs/fetchFarmerAndHGsSourceDatas', { sourceDataType: 6 })
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('farmerhgs/setFarmerAndHGsSourceData', response.result);
            }
          })
      }
    },
  },
  computed: {
    selectedModule() {
      return this.$store.state.encyclopedia.selectedModule;
    },
    selectedPopDetail() {
      return this.$store.state.encyclopedia.selectedPopDetail;
    },
  },
  created() {
    this.onLoadFarmerHGsData();
  },
}

</script>

<style lang="scss">
.encyclopedia-container {
  width:100%;

  .title {
    h4 {
      font-size: 1.2rem;
      font-weight: bold;
    }
    p {
      line-height: 1.2;
      font-size: 0.8rem;
      color: var(--lab-stieglitz-silver);
    }
  }

  .encyclopedia-body {
    padding: 0 2rem 1rem 2rem;
  }

  .pagination:not([class*=pagination-]) .page-item.active .page-link {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
  .page-item.next-item .page-link:hover ,.page-item.prev-item .page-link:hover {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
  .table-action .lab-text-steel:hover {
    color: var(--lab-g25-supplementary-color) !important;
  }

  .dashboard-table td {
    color: var(--lab-trolley-grey) !important;
  }
}

.dark-layout .encyclopedia-container {
  .title h4 {
    color: var(--lab-g25-supplementary-color);
  }
  .title p {
    color: var(--lab-doctor);
  }
  .dashboard-table th {
    color: var(--lab-g25-supplementary-color) !important;
  }
  .dashboard-table td {
    color: var(--lab-doctor) !important;
  }
  .pagination:not([class*=pagination-]) .page-item.active .page-link {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
  .pagination:not([class*=pagination-]) .page-item .page-link {
    background-color: #13181c !important;
  }
  .pagination:not([class*=pagination-]) .page-item.active {
    background-color: #13181c !important;
  }

  .encyclopedia-mobile-item {
    .item-info {
      h5 {
        color: var(--lab-g25-supplementary-color);
      }
      h6 {
        color: var(--lab-doctor); 
      }
    }
  }
}

.mobile-encyclopedia-icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.encyclopedia-mobile-item {
  position: relative;
  margin-bottom: 15px;
  padding: 1rem;
  .mobile-item-top ,
  .mobile-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }

  .mobile-item-bottom  {
    margin-top: 10px;
  }
  .item-info {
    h5 {
      color: var(--lab-carbon-fiber);
    }
    h6 {
      color: var(--lab-stieglitz-silver); 
    }
  }
  .left-info {
    width: 60%;
  }
  .right-info {
    width: 40%;
  }
}

.lab-switch input:checked + .slider {
  background-color: var(--lab-g25-supplementary-color);
}

.encyclopedia-padding {
  padding: 1.5rem 2.4rem 0.5rem 2.4rem;
}
@media screen and (min-width: 770px) { 
 .encyclopedia-container  {
    background-color: var(--bg-card-color);
    border: var(--lab-card-border);
    box-shadow: var(--lab-card-shadow);
    border-radius: 15px;
    height: 100%;
 }
}
@media screen and (max-width: 769px) { 
  .encyclopedia-padding {
    padding: 0 1.5rem;
  }
 .encyclopedia-container  {
    .encyclopedia-body {
      padding: 0 0;
    }
 }
}
</style>
