<template>
  <div>
    <div class="encyclopedia-filter">
      <div class="search">
        <b-form-input class="d-inline-block mr-1" size="sm" placeholder="ID/Name/Haplogroup" 
          v-model="$store.state.encyclopedia.filterQuery.Search"/>
      </div>
      <div class="filter">
        <div class="lab-dropdown sm">
          <div class="lab-dropdown-btn" @click="isShowDropdownModule = !isShowDropdownModule" ref="dropdownModule">
            <span class="lab-dropdown-btn-value">{{ selectedModule }}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon />
            </span>
          </div>
          <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownModule}">
            <ul>
              <li v-for="moduleItem in moduleList" :key="moduleItem.id" 
                @click="onChangeModule(moduleItem)">
                  {{ moduleItem.title }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lab-dropdown sm">
          <div class="lab-dropdown-btn" @click="isShowDropdownLocation = !isShowDropdownLocation" ref="dropdownLocation">
            <span class="lab-dropdown-btn-value">{{ locationQuery === '' ? 'Location' : locationQuery }}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon />
            </span>
          </div>
          <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownLocation}">
            <ul>
              <li v-for="location in $store.state.encyclopedia.locationQueryList" :key="location" 
                @click="$store.state.encyclopedia.filterQuery.location = location">
                 {{ location === '' ? 'All Location' : location}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="filter">
        <button v-if="selectedModule == 'Ancient (Individual)'"  @click="onChangeCard()"
          class="lab-btn lab-btn-sm lab-btn-genetic-supplementary mr-1">
          {{ selectedCard }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  directives: {
  },
  components: {
    DropdownIcon,
  },
  data() {
    return {      
      isShowDropdownModule: false,
      isShowDropdownLocation: false,
      isShowDropdownCard: false,

      moduleList: [
        {
          id: 'ANCIENT',
          title: 'Ancient (Population)',
        },
        {
          id: 'MODERN',
          title: 'Modern (Population)',
        },
        {
          id: 'ANCIENT-INDIVIDUAL',
          title: 'Ancient (Individual)',
        },
      ],
    }
  },
  methods: {    
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownCard;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownCard = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownModule;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownModule = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownLocation;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownLocation = false;
        }
      } catch (error) {}
    },
    onChangeModule(moduleItem) {
      this.$store.state.encyclopedia.selectedModule = moduleItem.id;
      this.$store.commit('encyclopedia/resetFilterQuery');
      this.loadFilterDatas();
    },
    loadFilterDatas() {
      if (this.$store.state.encyclopedia.selectedModule === 'ANCIENT') {
        this.$store.dispatch('encyclopedia/fetchAncientProxFilterQuery', 'ancientproxdata/filterlist');
      } else if (this.$store.state.encyclopedia.selectedModule === 'ANCIENT-INDIVIDUAL') {
        this.$store.dispatch('encyclopedia/fetchAncientProxFilterQuery', 'PresetIndividualSample/filterlist');
      }
    },
    onChangeCard() {
      if (this.selectedCard === 'Map') {
        this.$store.state.encyclopedia.selectedCard = 'List';
      } else {
        this.$store.state.encyclopedia.selectedCard = 'Map';
      }
    },
  },
  computed: {
    locationQuery() {
      return this.$store.state.encyclopedia.filterQuery.location;
    },
    selectedModule() {
      return this.moduleList.find(x => x.id === this.$store.state.encyclopedia.selectedModule).title;
    },
    selectedCard() {
      return this.$store.state.encyclopedia.selectedCard;
    },
  },
  mounted() {
    if (this.$store.state.encyclopedia.selectedModule === 'ANCIENT') {
      this.$store.dispatch('encyclopedia/fetchAncientProxFilterQuery', 'ancientproxdata/filterlist');
    } else if (this.$store.state.encyclopedia.selectedModule === 'ANCIENT-INDIVIDUAL') {
      this.$store.dispatch('encyclopedia/fetchAncientProxFilterQuery', 'PresetIndividualSample/filterlist');
    }
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}

</script>

<style lang="scss" scoped>

.encyclopedia-filter {
  .search {
    width: 100%;
    text-align: right;
    margin-bottom: 10px;
    input {
      width: 100%;
    }
  }
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.7rem;

    .lab-dropdown  {
      width: 50%;
      .lab-dropdown-content {
        width: 100%;
      }
      svg {
        fill: var(--lab-white);
      }
    }
    
    .lab-dropdown-btn  {
      color: var(--lab-white);
      background-color: var(--lab-carbon-fiber);
    }
  }
}

.dark-layout .encyclopedia-filter {
  .search {
    input {
      background-color: var(--bg-card-color) !important;
    }
  }
  .lab-dropdown .lab-dropdown-btn {
    background-color: var(--lab-neverything);
    color: var(--lab-g25-supplementary-color);;
    border: 2px solid var(--lab-g25-supplementary-color);;
  }
  .lab-dropdown .lab-dropdown-btn svg {
    fill: var(--lab-g25-supplementary-color);
  }
}

</style>
