<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-ancestry-break"
      title="Modifying Populations"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @hidden="hiddenModal"
      size="lg"
      content-class="select-ancestry-pop-modal"
    >
      <b-row class="p-2">
        <b-col sm="6" md="6">
          <label class="lab-checkbox-container">
            <span class="label"> {{ 'Select All' }}</span>
            <input type="checkbox" v-model="isActiveAll" @change="onToggleActive()">
            <span class="checkmark"></span>
          </label>
        </b-col>
        <b-col sm="6" md="6">
          <div class="lab-dropdown region">
            <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
              <span class="lab-dropdown-btn-value">{{ pop === 0 ? 'No limit' : pop + ' Pop'}}</span>
              <span class="lab-dropdown-btn-icon">
                <dropdown-icon />
              </span>
            </div>
            <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
              <ul>
                <li @click="pop = item.value" v-for="item in popsOptions" :key="item.value">
                  {{item.label}}
                </li>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <div class="select-data-card cs-scroll-style">
        <div class="pop-item" :class="{'selected': item.isActive}"
          v-for="item in sourceDatas" :key="item.sourceCode">

          <label class="lab-checkbox-container">
            <span class="label">{{ item.sourceCode.replaceAll('*', ' ') }}</span>
            <input type="checkbox" v-model="item.isActive">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <template #modal-footer>
        <!-- <b-button variant="secondary" class="float-right" @click="hiddenModal">
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          {{ $t("Close") }}
        </b-button> -->
        <button class="lab-btn float-right" @click="onSetDefault">
          <b-spinner
            v-if="isStartRunning"
            class="mr-1"
            small
            variant="light"
          />
          {{ $t("Reset") }}
        </button>
        <button class="lab-btn float-right" @click="onClickRecalculate">
          <b-spinner
            v-if="isStartRunning"
            class="mr-1"
            small
            variant="light"
          />
          {{ $t("Recalculate") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import IllusDnaService from '@/common/custom-service/IllusDnaService'

import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShowDropdown: false,
      popsOptions: [
        { value: 0, label: 'No Pop Limit' },
        { value: 3, label: '3 Pop' },
        { value: 4, label: '4 Pop' },
        { value: 5, label: '5 Pop' },
      ],
      pop: 0,
      sourceDatas: [],
      searchQuery: '',
      isActiveAll: true,
      isStartRunning: false,
    }
  },
  methods: {
    onToggleActive() {
      this.sourceDatas.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },
    onClickRecalculate() {
      this.reRun();
    },
    onSetDefault() {
      this.pop = 0;
      if (this.$store.getters['orderResult/getOrderSelectedRegion'] === 1) {
        this.pop = 3;
      }
      this.sourceDatas.forEach(item => {
        item.isActive = true;
      });
      this.reRun();
    },

    reRun() {
      this.isStartRunning = true;
      const orginalData = IllusDnaService.dataArrays.sourceCodeArray.map(x => x);
      const orginalSouceNumber = IllusDnaService.dataArrays.sourceCodeNumber;

      const activeList = this.sourceDatas.filter(x => x.isActive);
      if (activeList.length === 0) {
        AlertService.warning(this, 'Please select population');
        this.isStartRunning = false;
        return;
      }
      /* eslint-disable */
      if (this.$store.getters['getOrderSelectedRegion/getOrderSelectedRegion'] === 1) {
        this.$store.state.ancestrybreakdown.numberOfDefaulnPop = this.pop;
      } else {
        this.$store.state.ancestrybreakdown.numberOfDefaulnPop = this.pop;
      }
      
      IllusDnaService.dataArrays.sourceCodeArray = IllusDnaService.dataArrays.sourceCodeArray.filter(item => {
        let sourceCode = item[0];
        if (sourceCode.includes(':')) {
          sourceCode = sourceCode.split(':')[0];
        }
        if(activeList.find(x => x.sourceCode === sourceCode)) {
          return true;
        }
        return false;
      });
      IllusDnaService.dataArrays.sourceCodeNumber = IllusDnaService.dataArrays.sourceCodeArray.length;
      this.modalObject.onSuccess();
      this.isStartRunning = true;

      IllusDnaService.dataArrays.sourceCodeArray = orginalData;
      IllusDnaService.dataArrays.sourceCodeNumber = orginalSouceNumber;
      
      if (this.$store.getters['getOrderSelectedRegion/getOrderSelectedRegion'] === 1) {
        this.$store.state.ancestrybreakdown.numberOfGlobalnPop = 3;
      } else {
        this.$store.state.ancestrybreakdown.numberOfDefaulnPop = 0;
      }

      this.hiddenModal();
    },

    initialModal() {
      if (this.$store.getters['getOrderSelectedRegion/getOrderSelectedRegion'] === 1) {
        this.popsOptions.shift();
        this.pop = 3;
      }
      /* eslint-disable */
      IllusDnaService.dataArrays.sourceCodeArray.forEach(item => {
        let sourceCode = item[0];
        if (sourceCode.includes(':')) {
          sourceCode = sourceCode.split(':')[0];
        }
        if (!this.sourceDatas.find(x => x.sourceCode === sourceCode)) {
          this.sourceDatas.push({
            sourceCode,
            isActive: true,
          });
        }
      });
      this.$refs['modal-ancestry-break'].show();
    },

    hiddenModal() {
      this.$refs['modal-ancestry-break'].hide()
      this.modalObject.showModal = false;
    },
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.initialModal()
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss">
.select-ancestry-pop-modal {
  .lab-btn {
    background-color: var(--lab-g25-supervised-color) !important;
  }
  .select-data-card {
    padding: 1.2rem;
    height: 400px !important;
    overflow-y: scroll !important;
  }

  .pop-item {
    padding: 1rem;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  .pop-item:hover {
    background: var(--bg-card-gray);
  }
  .pop-item.selected {
    background: var(--bg-card-gray);
  }

  .lab-checkbox-container input:checked ~ .checkmark {
    background-color: var(--lab-g25-supervised-color) !important;
  }
}

.dark-layout .select-ancestry-pop-modal {
  .modal-header h5 {
    color: var(--lab-g25-supervised-color) !important;
  }
  .lab-dropdown .lab-dropdown-btn {
    color: var(--lab-g25-supervised-color);
    border: 2px solid var(--lab-g25-supervised-color);
  }

  .lab-dropdown .lab-dropdown-btn svg {
    fill: var(--lab-g25-supervised-color);
  }
}


</style>
