<template>
  <div class="table-container">
    <table class="dashboard-table hidden-mobile">
      <thead>
        <tr>
          <th scope="col" class="pop-col">Population</th>
          <th scope="col" class="detail-col">Details</th>
        </tr>
      </thead>
      <tbody class="cs-scroll-style">
        <tr v-for="item in popList" :key="item.title">
          <td class="pop-col">{{item.title.replaceAll('*', ' ')}}</td>
          <td class="detail-col">
            <span class="table-action table-action-edit lab-p-8">
              <feather-icon
                @click="openDetail(item.title)"
                icon="ArrowRightIcon"
                class="cursor-pointer lab-text-steel"
                size="25"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="encyclopedia-mobile hidden-desktop">
      <div v-for="item in popList" :key="item.title">
        <div class="encyclopedia-mobile-item dashboard-card">
          <div>
            <div class="mobile-item-top">
              <div class="item-info left-info">
                <h5>Population</h5>
                <h6>{{item.title.replaceAll('*', ' ')}}</h6>
              </div>
            </div>
          </div>
          <div class="mobile-encyclopedia-icon">
            <span class="cursor-pointer" @click="item.isOpenDetail = !item.isOpenDetail"> 
              <circle-plus-icon v-show="!item.isOpenDetail" className="encyclopedia"/>
              <circle-x-icon v-show="item.isOpenDetail" className="encyclopedia"/>
            </span>
          </div>
        </div>

        <encyclopedia-detail-mobile v-if="item.isOpenDetail" :popData="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';
import EncyclopediaDetailMobile from './EncyclopediaDetailMobile.vue';

export default {
  components: {
    CirclePlusIcon,
    CircleXIcon,
    EncyclopediaDetailMobile,
  },
  data() {
    return {
      popList: [],
    }
  },
  methods: {
    loadAncient() {
      /* eslint-disable */
      if (!this.$store.state.modernpopulation.modernSourceDatasResult.length) {
        this.$store.dispatch('modernpopulation/fetchModernSourceDatas', { sourceDataType: 2 })
          .then(() => {
            this.configurePopList();
          });
      } else {
        this.configurePopList();
      }
    },
    configurePopList() {
      let dataList = this.$store.state.modernpopulation.modernSourceDatasResult.map(x => {
        return {
          title: x[0],
          isOpenDetail: false,
        }
      });
      if (this.changePageSearch !== null) {
        let search = this.changePageSearch;
        if (search) {
          search = search.replaceAll(/ /g, '*');
        }
        dataList = dataList.filter(x => x.title.toLowerCase().includes(search.toLowerCase()));
      }
      this.popList = dataList.sort();
    },
    openDetail(item) {
      this.$store.state.encyclopedia.selectedPopDetail = item;
    },
  },
  computed: {
    changePageSearch() {
      return this.$store.getters['encyclopedia/getFilterQuery'].Search;
    },
  },
  watch: {  
    changePageSearch() {
      this.configurePopList();
    },
  },
  mounted() {
    this.loadAncient();
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  margin-bottom: 2rem;
  .dashboard-table {
    border-collapse: collapse;
  }

  thead, tbody {
    display: block;
  }

  tbody {
    width: 100%;
    max-height: 500px; /* Adjust the max height as needed */
    overflow-y: auto;
  }

  th {
    border-bottom: none;
  }

  .pop-col
  {
    width: 210px;
  }

  .detail-col
  {
    width: 600px;
  }

  td {
    font-size: 0.85rem;
  }
}

</style>
