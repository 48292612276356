<template>
  <div class="mb-4">    
    <div class="d-flex align-items-center justify-content-between">
      <diy-steps-info/>
      <diy-ancient-help />
    </div>
    
    <b-row class="mt-1">
      <b-col cols="12" xl="8" md="8">
        <div class="map-card mb-2">
          <div class="map-header">
            <div class="d-flex">
              <div class="map-header-fit" v-if="customizeResults">
                <span><b>Fit: </b></span>
                <span :style="{ color: CalculateModernPopColor(customizeResults.result) }"> {{ customizeResults.result }} ({{AncestryFitStatus(customizeResults.result)}})</span>
              </div>
              <div class="vertical-hr "></div>
              <div class="select-pop-btn" @click="selectPopModalObject.showModal = true">
                <select-pop-icon /> 
                Select <span class="hidden-mobile">Populations</span>
              </div>
              <div class="vertical-hr hidden-mobile"></div>
              <ancient-diy-pop class="hidden-mobile" />
            </div>
            <div class="d-flex calculate-btn-card">
              <ancient-diy-pop class="hidden-desktop mr-2" />
              <button class="lab-btn btn-select-pop" @click="onCalculate">
                <b-spinner
                  v-if="isStartRunning"
                  class="mr-1"
                  small
                  variant="light"
                />
                Calculate
              </button>
            </div>
          </div>
          <hr />
          <div class="map-body">
            <ancient-break-down-map :geoList="ancestryGeoJson">
            </ancient-break-down-map>
          </div>
         </div>
      </b-col>
      <b-col cols="12" xl="4" md="4">
        <ancient-pop-view />
      </b-col>
    </b-row>
    <AncientDiySelectPopModal :modalObject="selectPopModalObject" v-if="selectPopModalObject.showModal"/>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ApiService from '@/common/api.service'
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'

import SelectPopIcon from '@/layouts/icons/SelectPopIcon.vue'
import AncientDiyPop from './AncientDiyPop.vue'
import AncientPopView from './AncientPopView.vue'
import AncientBreakDownMap from '../../maps/AncientBreakDownMap.vue'
import AncientDiySelectPopModal from './AncientDiySelectPopModal.vue'

import DiyAncientHelp from './helps/DiyAncientHelp.vue'
import DiyStepsInfo from './helps/DiyStepsInfo.vue'

export default {
  components: {
    AncientDiyPop,
    AncientPopView,
    AncientBreakDownMap,
    AncientDiySelectPopModal,
    SelectPopIcon,

    DiyAncientHelp,
    DiyStepsInfo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      /* eslint-disable */
      itemView: 'grid-view',
      modalObject: {
        item: null,
        showModal: false,
      },
      selectPopModalObject: {
        showModal: false,
      },
      guideLineModalObject: { showModal: false, code: 'ancrestryBreakDown' },
      isStartRunning: false,
      isSelectedAll: true,
    }
  },
  methods: {
    onRun() {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.onChangeCustomAncBrDn(true);
    },
    onCalculate() {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.isStartRunning = true;
      setTimeout(() => {
        this.onChangeCustomAncBrDn();
        this.isStartRunning = false;
      });
    },

    // Runs
    onChangeCustomAncBrDn(isInitialize) {
      const selectedResultId = this.$store.state.orderResult.selectedResultId;

      const selectedResult = this.$store.state.customancestrybreakdown.customAncBrDnResults.find(x => x.selectedResultId === selectedResultId);
      if (selectedResult && isInitialize) {
        this.onRunCustomAncBrDn();
      } else if (isInitialize) {
        const query = {
          selectedResultId: selectedResultId,
          sourceDataType: 8,
        }

        this.$store.dispatch('customancestrybreakdown/fetchCustomBrDnSourceDatas', query)
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('customancestrybreakdown/setCustomAnBrDnResult', response.result);
              
              if (!response.result.isHaseResult || response.result.result === '[]') {
                this.$store.commit('customancestrybreakdown/runSingleCustomAnBrDn');
                const results = this.$store.getters['customancestrybreakdown/getCustomAncBrDnResult'];
                const saveResultQuery = {
                  calculatedResult: JSON.stringify(results),
                  orderResultId: selectedResultId,
                };
                ApiService.postSilent('result/customanbrdn', saveResultQuery, this);
              }
              
              this.onRunCustomAncBrDn();
            } else {
              AlertService.error(this, response.message)
            }
          });
      } else {
        this.$store.commit('customancestrybreakdown/runSingleCustomAnBrDn');
        const results = this.$store.getters['customancestrybreakdown/getCustomAncBrDnResult'];
        const saveResultQuery = {
          calculatedResult: JSON.stringify(results),
          orderResultId: selectedResultId,
        };
        ApiService.postSilent('result/customanbrdn', saveResultQuery, this);
        this.onRunCustomAncBrDn();
      }
    },
    onRunCustomAncBrDn() {
      const results = this.$store.getters['customancestrybreakdown/getCustomAncBrDnResult'];
      const resultCustom = results.outPuts.map(item => item.resultsTable);
      this.$store.dispatch('operation/fetchMapSourceDatas', { listOfSourceData: resultCustom })
        .then(mapResponse => {
          if (mapResponse.statusCode === 200) {
            mapResponse.result.forEach(geoItem => {
              /* eslint-disable */
              let isFoundData = false;
              geoItem.title = 'Map Title Not Found';
              results.outPuts.forEach(resultItem => {
                if (resultItem.resultsTable === geoItem.sourceCode) {
                  resultItem.color = geoItem.color;
                  resultItem.pictureUrl = geoItem.pictureUrl;
                  resultItem.id = geoItem.id;
                  resultItem.sourceCode = geoItem.sourceCode;

                  geoItem.title = resultItem.resultsTable.replaceAll('*', ' ') + ' : ' + resultItem.currentResult.toFixed(1);
                  geoItem.name = resultItem.resultsTable.replaceAll('*', ' ');
                  geoItem.ancestryValue = resultItem.currentResult.toFixed(1);

                  isFoundData = true;
                }
              });

              if (isFoundData === false) {
                geoItem.title = geoItem.sourceCode.replaceAll('*', ' ') + ' : ' + 0;
                geoItem.name = geoItem.sourceCode.replaceAll('*', ' ');
                geoItem.ancestryValue = 0;
                geoItem.color = '#b8b8b8';
              }
            });
            this.$store.commit('operation/setAncestryGeoJson', mapResponse.result)
            this.$store.commit('customancestrybreakdown/setCustomAncBrDnResultFinalResults', results)
          } else {
            AlertService.error(this, mapResponse.message)
          }
        });
    },

    // Options
    CalculateModernPopColor,
    AncestryFitStatus(value) {
      if(value > 0 && value <= 1) {
        return 'Very Good';
      }
      if(value > 1 && value <= 3) {
        return 'Good';
      }
      if(value > 3 && value <= 5) {
        return 'Moderate';
      }
      return 'Bad';
    },
  },
  computed: {
    customizeResults() {
      return this.$store.getters['customancestrybreakdown/getCustomAncBrDnFinalResult'];
    },
    ancestryGeoJson() {
      return this.$store.getters['operation/getAncestryGeoJson'];
    },
  },
  mounted() {
    this.$root.$refs.AncientDiyModel = this;
  },
}
</script>

<style lang="scss" scoped>
.btn-select-pop {
  background-color: var(--lab-g25-diy-color) !important;
}

.map-card {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  border: var(--lab-card-border);
  padding: 1.1rem;
  border-radius: 10px;

  .map-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .map-header-fit {
      margin-top: 5px;
    }
    .select-pop-btn {
      margin-top: 5px;
      color: var(--lab-carbon-fiber);
      font-size: 0.9rem;
      font-weight: bold;
      cursor: pointer;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
  .map-body {
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
  }
}

.dark-layout .map-card .select-pop-btn  {
  color: var(--lab-g25-diy-color);
}

@media screen and (max-width: 769px) { 
  .map-card {
    .map-header {
      display: block !important;
    }
    .calculate-btn-card {
      margin-top: 2rem;
    }
  }
}
</style>