<template>
  <div class="help-container">
    <button class="lab-btn lab-btn-help" @click="toggleHelp();">
      <help-icon />  Help
    </button>

    <div class="help-body" :class="{ 'show' : isOpenHelp}">
      <h5>? Help</h5>
      <hr />
      <div class="help-content">
        <p>
          The 'Unsupervised Ancient' section 
          displays the most suitable 2 and 3 
          ancient populations  or sampes that 
          can represent your genetic profile. 
          The populations listed do not 
          necessarily imply direct descent; 
          the section aims to provide insights 
          into the composition of your genetic 
          makeup.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from '@/layouts/icons/HelpIcon.vue'

export default {
  components: {
    HelpIcon,
  },
  props: {
  },
  data() {
    return {
      isOpenHelp: false,
    }
  },
  methods: {
    toggleHelp() {
      this.isOpenHelp = !this.isOpenHelp;
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>

</style>
