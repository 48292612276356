<template>
  <div class="lab-dropdown region">
    <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownPeriod">
      <span class="lab-dropdown-btn-value">{{ selectedPeriodName}}</span>
      <span class="lab-dropdown-btn-icon">
        <dropdown-icon />
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
      <ul>
        <li v-for="periodItem in $store.state.ancientMixedmode.mixedModePeriods"
            :key="periodItem.id"
            @click="onChangePeriod(periodItem)">
            {{ periodItem.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  props: {
    Initialize: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isShowDropdown: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownPeriod;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },
    onChangePeriod(periodItem) {
      this.$store.commit('ancientMixedmode/setMixedModerPeriod', periodItem);
      this.$store.commit('ancientMixedmode/setMixedModeResult', {});
      this.Initialize();
    },
  },
  computed: {
    selectedPeriodName() {
      const period = this.$store.state.ancientMixedmode.selectedPeriod;
      if(period) {
        return period.name;
      }

      return 'Select Period';
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.done .tvar {
  background-color: var(--lab-g25-unsupervised-color) !important;
}
.line-item.done:after {
  background-color:  var(--lab-g25-unsupervised-color) !important;
}
.line-item.done:before {
  background-color: var(--lab-g25-unsupervised-color) !important;;
}
.line-item.done.active:after {
  background-color:  #C4C4C4 !important;
}

.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-g25-unsupervised-color);
  border: 2px solid var(--lab-g25-unsupervised-color);
}

.dark-layout .lab-dropdown .lab-dropdown-btn svg {
  fill: var(--lab-g25-unsupervised-color);
}
</style>
