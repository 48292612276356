<template>
  <div class="lab-dropdown region">
    <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
      <span class="lab-dropdown-btn-value">{{ $store.state.modernbreakdown.modernBreakDownnPop === 0 ? 'No limit' : $store.state.modernbreakdown.modernBreakDownnPop + ' Pop'}}</span>
      <span class="lab-dropdown-btn-icon">
        <dropdown-icon />
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
      <ul>
        <li @click="onChangePop(3)">
          3
        </li>
        <li @click="onChangePop(4)">
          4
        </li>
        <li @click="onChangePop(5)">
          5
        </li>
        <li @click="onChangePop(0)">
          No limit
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  props: {
  },
  data() {
    return {
      isShowDropdown: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },

    onChangePop(value) {
      this.$store.state.modernbreakdown.modernBreakDownnPop = value;
    },
  },
  computed: {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.lab-dropdown .lab-dropdown-content {
  width: 100%;
}
.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-g25-diy-color);
  border: 2px solid var(--lab-g25-diy-color);
}
.dark-layout .lab-dropdown .lab-dropdown-btn svg {
  fill: var(--lab-g25-diy-color);
}
</style>
