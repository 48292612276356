<template>
  <div class="table-container">
    <div v-if="selectedCard === 'Map'">
      <div class="encylopedia-table-container  cs-scroll-style">
        <table class="dashboard-table hidden-mobile">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Date</th>
              <th scope="col" width="30">Label</th>
              <th scope="col">yDNA</th>
              <th scope="col">mtDNA</th>
              <th scope="col">location</th>
              <th scope="col">Source</th>
              <th scope="col" class="text-center">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in popList" :key="item.id">
              <td>{{item.sampleID}}</td>
              <td>
                {{item.date}}
              </td>
              <td width="30">{{ item.name }}</td>
              <td>
                <span v-if="item.yDNAISOGG.length > 1 && item.yDNATM.length > 1">{{item.yDNAISOGG}} ({{item.yDNATM}})</span>
                <span v-else-if="item.yDNAISOGG.length > 1">{{item.yDNAISOGG}}</span>
                <span v-else-if="item.yDNATM.length > 1">{{item.yDNATM}}</span>
              </td>
              <td>{{item.mtDNA}}</td>
              <td>{{item.location}}</td>
              <td> <a :href="item.researchLink" target="_blank">{{item.research}}</a></td>
              <td class="text-center">
                <span class="table-action table-action-edit lab-p-8">
                  <feather-icon
                    @click="openDetail(item)"
                    icon="ArrowRightIcon"
                    class="cursor-pointer lab-text-steel"
                    size="25"
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="encyclopedia-mobile hidden-desktop">
        <div v-for="item in popList" :key="item.id">
          <div class="encyclopedia-mobile-item dashboard-card">
            <div>
              <div class="mobile-item-top">
                <div class="item-info left-info">
                  <h5>Label</h5>
                  <h6>{{ item.name }} ({{item.yDNATM}},{{item.yDNAISOGG}})</h6>
                </div>
                <div class="item-info right-info">
                  <h5>ID</h5>
                  <h6>{{item.sampleID}}</h6>
                </div>
              </div>
              <div class="mobile-item-bottom">
                <div class="item-info left-info">
                  <h5>Date</h5>
                  <h6>{{item.date}}</h6>
                </div>
                <div class="item-info right-info">
                  <h5>Location</h5>
                  <h6>{{item.location}}</h6>
                </div>
              </div>
            </div>
            <div class="mobile-encyclopedia-icon">
              <span class="cursor-pointer" @click="item.isOpenDetail = !item.isOpenDetail"> 
                <circle-plus-icon v-show="!item.isOpenDetail" className="encyclopedia"/>
                <circle-x-icon v-show="item.isOpenDetail" className="encyclopedia"/>
              </span>
            </div>
          </div>

          <encyclopedia-detail-mobile v-if="item.isOpenDetail" :popData="item"/>
        </div>
      </div>

      <div class="mx-2 mb-2 mt-3">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1" >
            <span class="text-muted">
              Showing 
              {{ (pagination.PageIndex - 1) * pagination.PageSize }} to 
              {{ pagination.PageIndex * pagination.PageSize }} of {{ pagination.Count }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pagination.PageIndex"
              :total-rows="pagination.Count"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </div>
    <div v-if="selectedCard === 'List'">
      <AncientIndividualMap />
    </div>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';
import EncyclopediaDetailMobile from './EncyclopediaDetailMobile.vue';
import AncientIndividualMap from '../maps/AncientIndividualMap.vue';

export default {
  components: {
    CirclePlusIcon,
    CircleXIcon,
    EncyclopediaDetailMobile,
    AncientIndividualMap,
  },
  data() {
    return {
      popList: [],
    }
  },
  methods: {
    loadAncient() {
      const query = this.$store.getters['encyclopedia/getFilterQuery'];

      this.$store.dispatch('encyclopedia/fetchAncientIndividualData', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.popList = response.result.data.map(x => {
              x.isOpenDetail = false;
              return x;
            });
            this.$store.state.encyclopedia.filterQuery.Count = response.result.count;
          }
        });
    },
    openDetail(item) {
      this.$store.state.encyclopedia.selectedPopDetail = item;
    },
  },
  computed: {
    pagination() {
      return this.$store.getters['encyclopedia/getFilterQuery'];
    },

    changePageIndex() {
      return this.pagination.PageIndex;
    },
    changePageSearch() {
      return this.pagination.Search;
    },
    changePageDate() {
      return this.pagination.Date;
    },
    changePageLocation() {
      return this.pagination.location;
    },

    selectedCard() {
      return this.$store.state.encyclopedia.selectedCard;
    },
  },
  watch: {
    changePageIndex() {
      this.loadAncient();
    },

    changePageSearch() {
      this.loadAncient();
    },
    changePageDate() {
      this.loadAncient();
    },
    changePageLocation() {
      this.loadAncient();
    },
    selectedCard() {
      return this.$store.state.encyclopedia.selectedCard;
    },
  },
  mounted() {
    this.loadAncient();
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  .encylopedia-table-container {
    max-height: 500px; // Adjust this height as needed
    overflow-y: auto;

    .dashboard-table {
      width: 100%;
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--bg-card-color);
      }

      th, td {
        padding: 8px 12px;
        text-align: left;
      }

      td {
        font-size: 0.75rem;
      }

      a {
        color: var(--lab-g25-supplementary-color);
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
