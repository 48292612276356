<template>
  <div id="ancient-breakdown-map">
    <div id="mglBreakdownMap">
    </div>
    <GeoMapDetailModal v-if="modalObject.showModal" :modalObject="modalObject"/>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import GeoMapDetailModal from './GeoMapDetailModal.vue';
import ancientMapHelper from './ancientMapHelper'

export default {
  components: {
    GeoMapDetailModal,
  },
  props: {
  },
  data() {
    return {
      zoom: 1.5,
      maxZoom: 6,
      minZoom: 2,
      zIndex: 1,
      center: [44.312223, 30.046882],
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      modalObject: {
        item: null,
        showModal: false,
      },
      map: null,
    }
  },
  methods: {
    initMap() {
      /* eslint-disable*/
      mapboxgl.accessToken = 'pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2aXZqczB3cTczcm9ndndzN3JpNGMifQ.n68sFQDOi1PzNo34rRrpUw';
      const map = new mapboxgl.Map({
        container: 'mglBreakdownMap', 
        style: this.skin === 'light' ? 'mapbox://styles/ozenkaraocak/clckbuofy002a15oerwitho0u' : 'mapbox://styles/ozenkaraocak/cld4po7dj001a01kayqavpmpq', 
        center: this.center,
        zoom: this.zoom,
        attributionControl: false
      });

      map.on('load', () => {
        this.geoList.forEach(item => {
          
          let opacity = parseFloat(item.ancestryValue) / 100;
          opacity = opacity === 0 ? 0.30 : (opacity > 0.5 ? 0.5 : (opacity < 0.07 ? 0.07 : opacity));

          map.addSource(item.sourceCode, {
            'type': 'geojson',
            'data': typeof item.geoData === 'object' ? item.geoData: JSON.parse(item.geoData),
          });

          map.addLayer({
            'id': item.sourceCode,
            'type': 'fill',
            'source': item.sourceCode,
            'layout': {},
            'paint': {
              'fill-color': item.color,
              'fill-opacity': opacity
            }
          });

          map.on('mouseover', item.sourceCode, (e) => {
            map.getCanvas().style.cursor = 'pointer';
            if (e.features.length > 0) {
              ancientMapHelper.injectTooltip(e, item);
            }
          });

          map.on('mousemove', item.sourceCode, (e) => {
            if (e.features.length > 0) {
              ancientMapHelper.moveTooltip(e);
            }
          });

          map.on('mouseout', item.sourceCode, (e) => {
            map.getCanvas().style.cursor = '';
            ancientMapHelper.deleteTooltip(); 
          });

          map.on('click', item.sourceCode, (e) => {
            this.modalObject.item = item; 
            this.modalObject.showModal = true; 
          });

        });
      });
    },
  },
  computed: {
    geoList() {
      return this.$store.getters['operation/getAncestryGeoJson'];
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    }
  },
  watch: {
    skin(newValue, oldValue) {
      this.initMap();
      this.loadMap();
    },
    geoList(newValue, oldValue) {
      this.initMap();
      this.loadMap();
    },
  },
  mounted() {
    this.initMap();
    this.loadMap();
  },
}

</script>

<style lang="scss" scoped>
#mglBreakdownMap {
  height: 600px;
  @media screen and (max-width: 1500px) {
    height: 500px !important;
  }
}
#ancient-breakdown-map {
  height: 600px;
  @media screen and (max-width: 1500px) {
    height: 500px !important;
  }
}
</style>
