<template>
  <div class="">
    <div class="encyclopedia-detail dashboard-card">
      <div class="encyclopedia-detail-body">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <div class="title-header">
              <h4>{{ 'Genetic Profile' }}</h4>
            </div>
              <hr />
            <div class="desc p-1">
              <div v-for="itemAncestry in farmerHGsResult" :key="itemAncestry.resultsTable" class="farmer-hgs mb-1">
                <span><b>{{ itemAncestry.resultsTable.replaceAll('*', ' ') }} :</b></span>
                <span :style="{color: itemAncestry.color}" class="ml-75"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <div class="title-header">
              <h4>{{ 'Genetically Closest Populations' }}</h4>
              <label class="lab-switch xsm">
                <input type="checkbox" v-model="isModernClosest"  @change="onCalculateModernDist">
                <span class="slider round"></span>
              </label>
            </div>
              <hr />
            <div class="mt-1">
              <b-table
                :fields="modernFields"
                :items="distanceResult"
                responsive="sm"
                head-variant="light"
                small
                bordered
                outlined
              >
                <template #cell(title)="data">
                  <span> 
                    {{ data.item.title.replaceAll('*', ' ') }}
                  </span>
                </template>
                <template #cell(distance)="data">
                  <span :style="{ color: data.item.gradHSL }"> {{ data.item.distance }}</span>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="selectedModule === 'ANCIENT'" class="mt-2">
          <b-col cols="6">
            <div class="pop-desc">
              <div class="title-header">
                <h4>{{ 'Y-DNA' }}</h4>
              </div>
              <hr />
              <div class="desc">
                <ul>
                  <li v-for="item in yDna" :key="item">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="pop-desc">
              <div class="title-header">
                <h4>{{ 'mtDNA' }}</h4>
              </div>
              <hr />
              <div class="desc">
                <ul>
                  <li v-for="item in mtDna" :key="item">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="pop-desc">
              <div class="title-header">
                <h4>{{ 'Sample(s)' }}</h4>
              </div>
              <hr />
              <div class="desc">
                <ul>
                  <li v-for="item in samples" :key="item">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mobile-encyclopedia-icon">
        <span class="cursor-pointer" @click="popData.isOpenDetail = !popData.isOpenDetail"> 
          <circle-plus-icon v-show="!popData.isOpenDetail" className="encyclopedia"/>
          <circle-x-icon v-show="popData.isOpenDetail" className="encyclopedia"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IllusDnaService from '@/common/custom-service/IllusDnaService'
import farmerHgsHelpers from '@/store/results/g25/farmer-hgs/farmerHgsHelpers';
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';

export default {
  components: {
    CirclePlusIcon,
    CircleXIcon,
  },
  props: {
    popData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isModernClosest: true,
      farmerHGsResult: [],
      distanceResult: [],
      modernFields: [
        {
          // A virtual column with custom formatter
          key: 'distance',
          label: 'distance',
        },
        {
          // A regular column with custom formatter
          key: 'title',
          label: 'Population',
        },
      ],
    }
  },
  methods: {
    back() {
      this.$store.state.encyclopedia.selectedPopDetail = null;
    },
    
    onRunModernPop(item) {
      this.onSetTargetModern(item);
      this.onCalculateModernDist();
    },
    onSetTargetModern(item) {
      const target = this.$store.state.modernpopulation.modernSourceDatasResult.filter(x => x[0] === item.title);
      
      this.currentResult = target;
      if (target.length) {
        IllusDnaService.dataArrays.targetCodeArray = target;
        IllusDnaService.dataArrays.targetCodeNumber = target.length;
      }
    },

    onRunAncientIndividualPop(item) {
      this.onSetTargetAncientIndividual(item);
      this.onCalculateModernDist();
    },
    onSetTargetAncientIndividual(item) {
      const target = [item.g25Coordinate.split(',')];

      this.currentResult = target;
      if (target.length) {
        IllusDnaService.dataArrays.targetCodeArray = target;
        IllusDnaService.dataArrays.targetCodeNumber = target.length;
      }
    },

    onRunPop(item) {
      this.onSetTarget(item);
      this.onCalculateModernDist(item);
      if (this.selectedItem === item.name) {
        this.selectedItem = '';
      } else {
        this.selectedItem = item.name;
      }
    },
    onSetTarget(item) {
      this.onSetAncientSourceData(() => {
        const target = this.$store.state.ancientpopulation.ancientSourceDatasResult.filter(x => x[0] === item.name);

        this.currentResult = target;
        if (target.length) {
          IllusDnaService.dataArrays.targetCodeArray = target;
          IllusDnaService.dataArrays.targetCodeNumber = target.length;
        }
      });
    },
    onSetMainTarget() {
      if (!this.$store.getters['orderResult/getOrderResults'].length) {
        return;
      }
      const resolveSource = IllusDnaService.codeDataToArray(this.$store.state.orderResult.selectedTarget);
      if (resolveSource.isSuccess) {
        IllusDnaService.onSetTarget(resolveSource.result);
      }
    },

    onRunFarmerHGs(itemData) {
      const resolveSource = IllusDnaService.codeDataToArray(this.$store.state.farmerhgs.farmerAndHGsSourceData);
      if (resolveSource.isSuccess) {
        IllusDnaService.onSetSource(resolveSource.result);
      }

      const tempPop = this.$store.state.orderResult.IllusDnaService.dataObject.numberOfPopulation;
      IllusDnaService.dataObject.numberOfPopulation = 0;
      const result = IllusDnaService.runABAlgorithm();
      
      const setResult = [];
      farmerHgsHelpers._SetFarmerHGsABResults(setResult, 0, result);
      const newResult = setResult[0].result;
      this.$store.state.orderResult.IllusDnaService.dataObject.numberOfPopulation = tempPop;

      this.$store.dispatch('operation/fetchFarmerAndHGsDatas', { listOfSourceData: newResult.outPuts.map(itemValue => itemValue.resultsTable) })
        .then(response => {
          if (response.statusCode === 200) {
            this.farmerHGsResult = newResult.outPuts.map(item => {
              const findFarmerHGsItem = response.result.find(x => x.sourceCode === item.resultsTable);
              if (findFarmerHGsItem) {
                item.color = findFarmerHGsItem.color;
                item.id = findFarmerHGsItem.id;
                item.sourceCode = findFarmerHGsItem.sourceCode;
              }
              return item;
            });
          }
        });
    },

    onSetModernSourceData(callback) {
      if (this.$store.state.modernpopulation.modernSourceDatas.length) {
        this.$store.commit('modernpopulation/setModernSourceDatas', []);
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        this.$store.dispatch('modernpopulation/fetchModernSourceDatasSilent', { sourceDataType: 2 })
          .then(() => {
            if (typeof callback === 'function') {
              callback();
            }
          })
      }
    },
    onSetAncientSourceData(callback) {
      if (this.$store.state.ancientpopulation.ancientSourceDatas.length) {
        this.$store.commit('ancientpopulation/setAncientSourceDatas', []);
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        this.$store.dispatch('ancientpopulation/fetchAncientSourceDatas', { sourceDataType: 3 })
          .then(() => {
            if (typeof callback === 'function') {
              callback();
            }
          })
      }
    },

    // Run Dist
    onChangeRunDist(item) {
      this.onSetTarget(item);
      this.isModernClosest = !this.isModernClosest;
      this.onCalculateModernDist(item);
    },
    onCalculateModernDist(item) {
      this.distanceResult = [];
      IllusDnaService.dataObject.maxDistOutPut = 10;

      if (this.currentResult) {
        IllusDnaService.dataArrays.targetCodeArray = this.currentResult;
        IllusDnaService.dataArrays.targetCodeNumber = this.currentResult.length;
      }
      
      if (this.isModernClosest) {
        this.onSetModernSourceData(() => {
          this.distanceResult = IllusDnaService.calculateDistance(0);

          if (!this.farmerHGsResult.length) {
            this.onRunFarmerHGs(item);
          }
          this.onSetMainTarget();
        });
      } else {
        this.onSetAncientSourceData(() => {
          this.distanceResult = IllusDnaService.calculateDistance(0);

          if (!this.farmerHGsResult.length) {
            this.onRunFarmerHGs(item);
          }
          this.onSetMainTarget();
        });
      }
    },
  },
  computed: {
    selectedModule() {
      return this.$store.state.encyclopedia.selectedModule;
    },

    samples() {
      return this.popData.samples.split(',');
    },
    mtDna() {
      return this.popData.mtDna.split(',');
    },
    yDna() {
      return this.popData.yDna.split(',');
    },
  },
  mounted() {
    if (this.selectedModule === 'ANCIENT') {
      this.onRunPop(this.popData);
    } else if (this.selectedModule === 'MODERN') {
      this.onRunModernPop(this.popData);
    } else {
      this.onRunAncientIndividualPop(this.popData);
    }
  },
};
</script>

<style lang="scss" scoped>

.encyclopedia-detail {
  position: relative;
  padding: 0.8rem;
  margin-bottom: 20px;
  hr {
    margin-top: 0.7rem !important;
    margin-bottom: 0rem !important;
  }
  .ancient-hr {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .encyclopedia-detail-header {
    margin-bottom: 20px;
  }

  .encyclopedia-detail-body {
    padding: 1.5rem;

    .title-header::before {
      height: 18px;
      width: 6px;
    }

    .pop-desc {
      margin-bottom: 10px;
    }

    h4 {
      font-size: 1rem;
      color: var(--lab-carbon-fiber);
    }
    .desc {
      padding: 0.8rem 1rem;
      color: var(--lab-stieglitz-silver);
      font-size: 0.85rem;

      ul {
        padding: 0 1rem;
        li::marker {
          font-size: 1.2rem;
          color: var(--lab-g25-supplementary-color);
        }
      }
    }
  }
}

.lab-btn-back {
  padding: 0.4rem 0.9rem 0.4rem 0.8rem;
  background-color: var(--lab-light-house);
  color: var(--lab-stieglitz-silver);
  font-size: 0.9rem;
  font-weight: bold;
}

.title-header::before {
  background-color: var(--lab-g25-supplementary-color);
}

.dark-layout {
  .encyclopedia-detail .encyclopedia-detail-body .desc {
    color: var(--lab-doctor);
  }
  .encyclopedia-detail .encyclopedia-detail-body h4 {
    color: var(--lab-g25-supplementary-color);
  }
  .encyclopedia-detail .encyclopedia-detail-body h4 {
    color: var(--lab-g25-supplementary-color);
  }

  .lab-btn-back {
    background-color: var(--lab-neverything);
    color: var(--lab-doctor);
  }
}
</style>
