<template>
  <div class="help-container">
    <button class="lab-btn lab-btn-help" @click="toggleHelp();">
      <help-icon />  Help
    </button>

    <div class="help-body" :class="{ 'show' : isOpenHelp}">
      <h5>? Help</h5>
      <hr />
      <div class="help-content">
        <p>
          The 'Closest Modern Populations' 
          section displays the modern populations 
          most genetically similar to you. 
        </p>
        <p>
          The numbers under the 'Distance' 
          heading indicate the degree of 
          closeness or similarity, with lower 
          values signifying populations that 
          are closer to you.
        </p>
        <p>
          You can modify the number of 
          populations by using the slider 
          located at the top of the map and 
          exclude certain populations 
          through the 'Filter Populations' button.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from '@/layouts/icons/HelpIcon.vue'

export default {
  components: {
    HelpIcon,
  },
  props: {
  },
  data() {
    return {
      isOpenHelp: false,
    }
  },
  methods: {
    toggleHelp() {
      this.isOpenHelp = !this.isOpenHelp;
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>

</style>
