<template>
  <div class="table-container">
    <div>
      <table class="dashboard-table hidden-mobile">
        <thead>
          <tr>
            <th scope="col" width="30">Population</th>
            <th scope="col">Date</th>
            <th scope="col" class="text-center">Sample(s)</th>
            <th scope="col" class="text-center">Y-DNA</th>
            <th scope="col" class="text-center">mtDNA</th>
            <th scope="col">location</th>
            <th scope="col" class="text-center">Details</th>
          </tr>
        </thead>
        <tbody class="cs-scroll-style">
          <tr v-for="item in popList" :key="item.id">
            <td width="30">{{item.title}}</td>
            <td>
              {{item.date}}
            </td>
            <td class="text-center">{{ item.samples.split(',').length }}</td>
            <td class="text-center">{{item.yDna.split(',').length}}</td>
            <td class="text-center">{{item.mtDna.split(',').length}}</td>
            <td>{{item.location}}</td>
            <td class="text-center">
              <span class="table-action table-action-edit lab-p-8">
                <feather-icon
                  @click="openDetail(item)"
                  icon="ArrowRightIcon"
                  class="cursor-pointer lab-text-steel"
                  size="25"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="encyclopedia-mobile hidden-desktop">
        <div v-for="item in popList" :key="item.id">
          <div class="encyclopedia-mobile-item dashboard-card">
            <div>
              <div class="mobile-item-top">
                <div class="item-info left-info">
                  <h5>Population</h5>
                  <h6>{{item.title}}</h6>
                </div>
                <div class="item-info right-info">
                  <h5>Location</h5>
                  <h6>{{item.location}}</h6>
                </div>
              </div>
              <div class="mobile-item-bottom">
                <div class="item-info left-info">
                  <h5>Date</h5>
                  <h6>{{item.date}}</h6>
                </div>
                <div class="item-info right-info">
                  <h5>Sample(s)</h5>
                  <h6>{{item.samples.split(',').length}}</h6>
                </div>
              </div>
            </div>
            <div class="mobile-encyclopedia-icon">
              <span class="cursor-pointer" @click="item.isOpenDetail = !item.isOpenDetail"> 
                <circle-plus-icon v-show="!item.isOpenDetail" className="encyclopedia"/>
                <circle-x-icon v-show="item.isOpenDetail" className="encyclopedia"/>
              </span>
            </div>
          </div>

          <encyclopedia-detail-mobile v-if="item.isOpenDetail" :popData="item"/>
        </div>
      </div>

      <div class="mx-2 mb-2 mt-3">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1" >
            <span class="text-muted">
              Showing 
              {{ (pagination.PageIndex - 1) * pagination.PageSize }} to 
              {{ pagination.PageIndex * pagination.PageSize }} of {{ pagination.Count }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pagination.PageIndex"
              :total-rows="pagination.Count"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';
import EncyclopediaDetailMobile from './EncyclopediaDetailMobile.vue';

export default {
  components: {
    CirclePlusIcon,
    CircleXIcon,
    EncyclopediaDetailMobile,
  },
  data() {
    return {
      popList: [],
    }
  },
  methods: {
    loadAncient() {
      const query = this.$store.getters['encyclopedia/getFilterQuery'];

      this.$store.dispatch('encyclopedia/fetchAncientProxDatas', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.popList = response.result.data.map(x => {
              x.isOpenDetail = false;

              if (x.research !== null) {
                x.researchArray = x.research.split('\n');
              } else {
                x.researchArray = [];
              }

              return x;
            });
            this.$store.state.encyclopedia.filterQuery.Count = response.result.count;
          }
        });
    },
    openDetail(item) {
      this.$store.state.encyclopedia.selectedPopDetail = item;
    },
  },
  computed: {
    pagination() {
      return this.$store.getters['encyclopedia/getFilterQuery'];
    },

    changePageIndex() {
      return this.pagination.PageIndex;
    },
    changePageSearch() {
      return this.pagination.Search;
    },
    changePageDate() {
      return this.pagination.Date;
    },
    changePageLocation() {
      return this.pagination.location;
    },
  },
  watch: {
    changePageIndex() {
      this.loadAncient();
    },

    changePageSearch() {
      this.loadAncient();
    },
    changePageDate() {
      this.loadAncient();
    },
    changePageLocation() {
      this.loadAncient();
    },
  },
  mounted() {
    this.loadAncient();
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  .dashboard-table {
    border-collapse: collapse;
  }

  thead, tbody {
    display: block;
  }

  tbody {
    width: 100%;
    max-height: 500px; /* Adjust the max height as needed */
    overflow-y: auto;
  }

  th {
    border-bottom: none;
  }
  th, td
  {
    width: 130px;
  }

  td {
    font-size: 0.85rem;
  }
}
</style>
