<template>
  <svg id="katman_2" data-name="katman 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.22 16.32">
    <defs>

    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <polygon points="3.61 6.26 0 6.26 1.81 3.13 3.61 0 5.42 3.13 7.22 6.26 3.61 6.26"/>
        <polygon points="3.61 10.06 7.22 10.06 5.42 13.19 3.61 16.32 1.81 13.19 0 10.06 3.61 10.06"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
  .white {
    fill: #FFF;
  }
  .black {
    fill: #292D32;
  }
  .blue {
    fill: var(--lab-turquoise-sea);
  }
</style>
