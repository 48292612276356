<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-modern-data-selection"
      title="Filter Populations"  
      cancel-variant="outline-secondary"
      @hidden="hiddenModal"
      size="lg"
      content-class="genetice-dist-modern-select"
    >
      <b-row class="p-2">
        <b-col sm="6" md="6">
          <label class="lab-checkbox-container">
            <span class="label"> {{ 'Select All' }}</span>
            <input type="checkbox" v-model="isActiveAll" @change="onToggleActive()">
            <span class="checkmark"></span>
          </label>
        </b-col>
        <b-col sm="6" md="6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1 genetic-dist-form"
              size="sm"
              placeholder="Search..."
            />
        </b-col>
      </b-row>
      
      <div class="select-data-card cs-scroll-style">
        <div class="pop-item" :class="{'selected': item.isActive}"
          v-for="item in filteredSourceData" :key="item.id">

        <label class="lab-checkbox-container">
          <span class="label"> {{item.item[0].replaceAll('*', ' ')}}</span>
          <input type="checkbox" v-model="item.isActive">
          <span class="checkmark"></span>
        </label>
        </div>
      </div>

      <template #modal-footer>
        <!-- <b-button variant="secondary" class="float-right" @click="hiddenModal">
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          {{ $t("Close") }}
        </b-button> -->
        <button class="lab-btn float-right lab-btn-genetic-dist" @click="handleOk">
          {{ $t("Save") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sourceDatas: [],
      searchQuery: '',
      isActiveAll: true,
    }
  },
  methods: {
    onToggleActive() {
      this.sourceDatas.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },
    handleOk() {
      this.$store.state.modernpopulation.modernSourceDatas = this.sourceDatas;
      this.modalObject.onSuccess();
      this.hiddenModal();
    },
    initialModal() {
      this.sourceDatas = this.$store.state.modernpopulation.modernSourceDatas.map(item => item);
      this.$refs['modal-modern-data-selection'].show();
    },
    hiddenModal() {
      this.$refs['modal-modern-data-selection'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    filteredSourceData() {
      return this.sourceDatas.filter(item => item.item[0].toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.lab-checkbox-container input:checked ~ .checkmark {
  background-color: var(--lab-g25-genetic-dist-color) !important;
}
</style>

<style lang="scss">
.genetice-dist-modern-select {
  .modal-header::before {
    background-color: var(--lab-g25-genetic-dist-color) !important;
  }
  .modal-header .close {
    background-color: #ffb1294f !important;
    color: var(--lab-g25-genetic-dist-color) !important;
  }
}

.dark-layout .genetice-dist-modern-select {
  .modal-header h5 {
    color: var(--lab-g25-genetic-dist-color) !important;
  }
}

.genetic-dist-form:focus {
  border: 2px solid var(--lab-g25-genetic-dist-color) !important;
}
</style>
