<template>
  <div>
    <b-row>
      <b-col cols="6"  class="mobile-content">
        <div class="lab-dropdown region period">
          <div class="lab-dropdown-btn" @click="isShowDropdownPeriod = !isShowDropdownPeriod" ref="dropdownPeriod">
            <span class="lab-dropdown-btn-value">{{ $store.state.operation.selectedAncientPeriod.name }}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon :color="'white'"/>
            </span>
          </div>
          <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownPeriod}">
            <ul>
              <li v-for="periodItem in $store.state.operation.ancientPeriods"
                  :key="periodItem.id"
                  @click="onChangePeriod(periodItem)">
                  {{ periodItem.name }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="6" class="mobile-content">
        <div class="lab-dropdown region">
          <div class="lab-dropdown-btn" @click="isShowDropdownGroup = !isShowDropdownGroup" ref="dropdownRegionGrup">
            <span class="lab-dropdown-btn-value">{{ getSelectedRegionGrupName}}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon :color="'white'"/>
            </span>
          </div>
          <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownGroup}">
            <ul>
              <li v-for="regionItem in mapRegionGrups"
                  :key="regionItem.id"
                  @click="onSetRegionGrup(regionItem.id)">
                  {{ regionItem.grupName }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="mobile-content">
        <div class="lab-dropdown region">
          <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
            <span class="lab-dropdown-btn-value">{{ selectedRegionName}}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon :color="'white'"/>
            </span>
          </div>
          <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
            <ul>
              <li v-for="regionItem in mapRegions"
                  :key="regionItem.id"
                  @click="onSetRegion(regionItem.id)">
                  {{ regionItem.title }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  props: {
    runAncestryBreakDown: {
      type: Function,
      required: true,
    },
    selectedRegionName: {
      type: String,
      required: true,
    },
    mapRegions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShowDropdownGroup: false,
      isShowDropdown: false,
      isShowDropdownPeriod: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegionGrup;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownGroup = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownPeriod;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownPeriod = false;
        }
      } catch (error) {}
    },

    onSetRegionGrup(value) {
      this.$store.commit('ancestrybreakdown/setSelectedRegionGrup', value);
    },
    onSetRegion(value) {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.$store.commit('ancestrybreakdown/setSelectedRegion', value);
      this.runAncestryBreakDown(value);
    },
    onChangePeriod(periodItem) {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.$store.commit('operation/setSelectedPeriod', periodItem);
      
      this.runAncestryBreakDown();
    },
  },
  computed: {
    mapRegionGrups() {
      return this.$store.getters['operation/getRegionGrups'];
    },
    getSelectedRegionGrupName() {
      const regionGrupId = this.$store.getters['ancestrybreakdown/getOrderSelectedRegionGrup'];

      const findRegionGrup = this.mapRegionGrups.find(x => x.id === regionGrupId);

      return findRegionGrup != null ? findRegionGrup.grupName : 'Select Region';
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.mobile-content {
  padding: 0 10px !important;
}

.lab-dropdown.region .lab-dropdown-btn {
  background-color: var(--lab-carbon-fiber);
  color: var(--lab-white);
  border: none;
}

.lab-dropdown.region.period .lab-dropdown-btn {
  background-color: var(--lab-turquoise-sea);
  color: var(--lab-white);
  border: none;
}

.lab-dropdown.region .lab-dropdown-btn svg {
  fill: var(--lab-white);
}

.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-lago-blue-color);
  border: 2px solid var(--lab-lago-blue-color);
  background-color: transparent;
}

.dark-layout .lab-dropdown .lab-dropdown-btn svg {
  fill: var(--lab-lago-blue-color);
}

.dark-layout .lab-dropdown.period .lab-dropdown-btn svg {
  fill: var(--lab-white);
}
</style>
