<template>
  <div class="main-g25">
    <unsupervised-modern-help />
    <b-row class="mt-2">
      <b-col cols="12" xl="12" md="12">
        <div class="number-card">
          <div class="number-card-header">
            <h4 class="unsupervised-text">Number Of Models</h4>
            <button class="lab-btn lab-btn-outline-seccondary"
              v-if="mixedModeObject.mixedModeVersion === selectedPeriod.sourceCodeVersion">
              Latest Version
            </button>
            <button class="lab-btn lab-btn-outline-marigold" @click="onUpdateResult()"
              v-else>
              Update Available
            </button>
          </div>
          <div class="number-card-body">
            <div class="d-flex align-items-center justify-content-start">
              <div class="slider-container">
                <vue-slider
                  v-model="$store.state.modernMixedmode.modernMixedModePopNumber"
                  :adsorb="true"
                  :data="sliderData"
                  :marks="true"
                  direction="ltr"
                  class="mixed-mode-slider"
                />
              </div>
              <div class="lab-timeline-switch hidden-mobile">
                <div class="timeline-item" @click="switchLeft()">
                  <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_left.svg'" />
                </div>
                <div class="timeline-item last"  @click="switchRight()">
                  <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_right.svg'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="mixedModeObject.orderResultStatus === 3">
      <b-col cols="12" xl="12" md="12" class="mt-2">
        <b-alert
          variant="info"
          class="text-center"
          show 
        >
          <div class="alert-body lab-unsupervised-badge">
            <span><strong>These results are under process.</strong> Please check back in few hours.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="modernTwoWay.length && modernThreeWay.length">
      <b-col cols="12" xl="6" md="6" class="mt-2">
        <div class="mixedmode-container">
          <div class="mixedmode-container-top">
            <div class="mixedmode-container-header">
              <h4 class="unsupervised-text">Two Way</h4>
            </div>
          </div>
          <div class="mixedmode-container-body  cs-scroll-style">
            <div class="mixedmode" v-for="twoWay in modernTwoWay" :key="twoWay.id">
              <div class="mixedmode-header">
                <div class="text-number">
                  Model#{{ twoWay.id + 1 }}
                </div>
                <div class="text-fit">
                  Fit: 
                  <span :style="{ color: CalculateModernPopColor(twoWay.fit) }">{{ twoWay.fit }}</span>
                </div>
              </div>
              <div class="mixedmode-body">
                  <modern-mixed-mode-card :wayObject="twoWay"/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="6" md="6" class="mt-2">
        <div class="mixedmode-container">
          <div class="mixedmode-container-top">
            <div class="mixedmode-container-header">
              <h4 class="unsupervised-text">Three Way</h4>
            </div>
          </div>
          <div class="mixedmode-container-body  cs-scroll-style">
            <div class="mixedmode" v-for="threeWay in modernThreeWay" :key="threeWay.id">
              <div class="mixedmode-header">
                <div class="text-number">
                  Model#{{ threeWay.id + 1 }}
                </div>
                <div class="text-fit">
                  Fit: 
                  <span :style="{ color: CalculateModernPopColor(threeWay.fit) }">{{ threeWay.fit }}</span>
                </div>
              </div>
              <div class="mixedmode-body">
                  <modern-mixed-mode-card :wayObject="threeWay"/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'
import VueSlider from 'vue-slider-component'
import mixedModeHelper from './mixedModeHelper'

import ModernMixedModeCard from './cards/ModernMixedModeCard.vue'
import UnsupervisedModernHelp from './helps/UnsupervisedModernHelp.vue'

export default {
  components: {
    VueSlider,
    ModernMixedModeCard,
    UnsupervisedModernHelp,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      sliderData: ['10', '20', '30', '40', '50'],
    };
  },
  methods: {
    Initialize() {
      this.$store.commit('modernMixedmode/setMixedModeResult', {});
      if (this.$store.state.modernMixedmode.mixedModePeriods.length === 0) {
        this.$store.dispatch('modernMixedmode/fetchModernMixedModePeriods')
          .then(() => {
            this.onRun();
          });
      } else {
        this.onRun();
      }
    },
    onRun() {
      const findSelected = this.$store.getters['modernMixedmode/getSelectedMixedModeResult'];

      if (findSelected.id) {
        this.onSetMixedModeResult();
      } else {
        this.fetchMixedModeResults();
      }
    },

    // Runs
    onSetMixedModeResult() {
      const findSelected = this.$store.getters['modernMixedmode/getSelectedMixedModeResult'];
      if (findSelected) {
        const mixedModeResult = mixedModeHelper.MixedModeRunAll(findSelected);
        this.$store.commit('modernMixedmode/setSelectedModernMixedModeResult', { threeWayResult: mixedModeResult.threeWayResult, twoWayResult: mixedModeResult.twoWayResult });
      }
    },
    fetchMixedModeResults() {
      const resultId = this.$store.state.orderResult.selectedResultId;
      const periodId = this.$store.state.modernMixedmode.selectedPeriodId;
      if (resultId === null || periodId === 0) {
        return;
      }
      this.$store.dispatch('modernMixedmode/fetchOrderMixedModeResults', { orderResultId: resultId, mixedModePeriodId: periodId })
        .then(response => {
          if (response.statusCode === 200) {
            this.$store.commit('modernMixedmode/setMixedModeResult', response.result);
            this.onSetMixedModeResult();
          }
        });
    },

    // Options
    CalculateModernPopColor,
    onUpdateResult() {
      const resultId = this.$store.state.orderResult.selectedResultId;
      this.$store.dispatch('modernMixedmode/updateOrderResultMixedModeResults', { id: resultId })
        .then(response => {
          if (response.statusCode === 200) {
            this.mixedModeObject.orderResultStatus = 3;
            AlertService.success(this, response.message)
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    switchLeft() {
      /* eslint-disable */
      if (this.modernPopNumber != '10') {
        this.$store.state.modernMixedmode.modernMixedModePopNumber = (parseInt(this.modernPopNumber) - 10).toString();
      }
    },
    switchRight() {
      /* eslint-disable */
      if (this.modernPopNumber != '50') {
        this.$store.state.modernMixedmode.modernMixedModePopNumber = (parseInt(this.modernPopNumber) + 10).toString();
      }
    },
  },
  computed: {
    mixedModeObject() {
      const result = this.$store.getters['modernMixedmode/getSelectedMixedModeResult'];
      if (result) {
        return result;
      }
      return {};
    },
    modernThreeWay() {
      /* eslint-disable */
      const selectedMixedMode = this.$store.getters['modernMixedmode/getSelectedModernMixedModeResult'];
      const mixedModePopNumber = this.$store.getters['modernMixedmode/getModernMixedModePopNumber'];
      
      if (selectedMixedMode != null) {
        if (selectedMixedMode.threeWayResult) {
          return selectedMixedMode.threeWayResult.slice(0, mixedModePopNumber);
        }
      }

      return [];
    },
    modernTwoWay() {
      /* eslint-disable */
      const selectedMixedMode = this.$store.getters['modernMixedmode/getSelectedModernMixedModeResult'];
      const mixedModePopNumber = this.$store.getters['modernMixedmode/getModernMixedModePopNumber'];
      if (selectedMixedMode != null) {
        if (selectedMixedMode.twoWayResult) {
          return selectedMixedMode.twoWayResult.slice(0, mixedModePopNumber);
        }
      }

      return [];
    },
    selectedPeriod() {
      const period = this.$store.state.modernMixedmode.mixedModePeriods.find(x => x.id === this.$store.state.modernMixedmode.selectedPeriodId);

      return period ? period : { name: '' };
    },

    modernPopNumber() {
      return this.$store.getters['modernMixedmode/getModernMixedModePopNumber'];
    },
  },
  mounted() {
    this.$root.$refs.MixedModeModern = this;
  },
};
</script>

<style lang="scss">
@import '../../g25.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
.mixed-mode-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-unsupervised-color) !important;
}

.mixed-mode-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-unsupervised-color) !important;
  border-color: var(--lab-g25-unsupervised-color) !important;
}
.mixed-mode-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-unsupervised-color) !important;

  &-focus {
    box-shadow: 0px 0px 1px 2px #f5e3c4 !important;
  }
}

.mixed-mode-slider .vue-slider-process {
  background-color: var(--lab-g25-unsupervised-color) !important;
}

.mixed-mode-slider .vue-slider-rail {
  background-color:  #f5e3c4 !important;
}

.mixed-mode-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-unsupervised-color) !important;
  border-color: var(--lab-g25-unsupervised-color) !important;
}
</style>

<style lang="scss" scoped>
.slider-container {
  width: 90%;
  padding: 0 1rem;
}

@media screen and (max-width: 769px) {
  .slider-container {
    width: 100%;
  }
}

.lab-timeline-switch {
  display: flex;
  align-items: center;
  justify-content: end;
  .timeline-item {
    background-color: var(--lab-g25-unsupervised-color) !important;
  }
}
</style>
