<template>
  <div style="height: 530px;">
    <l-map ref="breakdownMap" :options="{ attributionControl: false }"
      :zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom" :center="center">
      <l-tile-layer :url="mapStyleUrl" :zIndex="zIndex"></l-tile-layer>
      <l-geo-json 
        v-if="geojson !== null"
        :geojson="geojson"
        :options="options"
      >
      </l-geo-json>
    </l-map>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import { CalculateModernMapPopColor } from '@/common/custom-service/dna-source/utils'

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {
    geoJsonData: {
      type: Object,
      required: true,
    },
    modernPopData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      zoom: 2,
      maxZoom: 6,
      minZoom: 2,
      zIndex: 1,
      center: [44.312223, 30.046882],
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      geojson: null,
      options: null,
    }
  },
  computed: {
    mapStyleUrl() {
      return this.skin === 'light' ? 'https://api.mapbox.com/styles/v1/ozenkaraocak/clski0yqq00h301qx4jbefjq9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2ajFocjBkcXIzb21wb250dGFuZXEifQ.A9TP4DXt4G4zYafoO7j5GQ'
        : 'https://api.mapbox.com/styles/v1/ozenkaraocak/cls68a1jk00pd01qq04jxf0y6/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2ajFocjBkcXIzb21wb250dGFuZXEifQ.A9TP4DXt4G4zYafoO7j5GQ';
    },
  },
  setup() { 
    const { skin } = useAppConfig()
    return { skin };
  },
  created() {
    /* eslint-disable*/
    this.geojson = this.geoJsonData;
    const geoJsonData = this.geoJsonData;
    const modernPopData = this.modernPopData;

    this.options = {
      style: styleFeature,
      onEachFeature: (feature, layer) => {
        layer.on({
          mouseover: mouseInToRegion,
          mousemove: moveTooltip,
          mouseout: mouseOutOfRegion,
        });
      },
    };

    function styleFeature(feature) {
      /* eslint-disable*/
      
      const findGeoDataProps = geoJsonData.features.find(item => {
        if (item.properties.zIndex === undefined) {
          item.properties.zIndex = 1;
        }
        return item.properties.gx_id === feature.properties.gx_id;
      });
      
      const findPopData = modernPopData.find(x => x.title === feature.properties.gx_id);
      const outlineWeight = 0.8;

      let distValue = 0;

      if (findPopData) {
        distValue = parseFloat(findPopData.distance);
      }
      
      if (feature.properties.isHover) {
        zIndex = 2;
      }
      
      return {
        weight: outlineWeight,
        color: CalculateModernMapPopColor(distValue),
        zIndex: findGeoDataProps.properties.zIndex,
        fillColor: CalculateModernMapPopColor(distValue),
        fillOpacity: 0.7,
        visible: distValue ? true : false,
      };
    }

    let tipObj = null;
    //offset along x and y in px
    const offset = {
        x: 5,
        y: -60
    };
    /**
     * Responds to the mouse-in event on a map shape (state).
     *
     * @param {?google.maps.MapMouseEvent} event
     */
    function mouseInToRegion(event) {
      const findModerPop = modernPopData.find(modernPopValue => event.target.feature.properties.gx_id === modernPopValue.title);

      event.target.feature.properties.isHover = true;
      
      tipObj = document.createElement("div");
      // tipObj.style.width = '200px';
      // tipObj.style.height = '60px';
      tipObj.style.background = "white";
      tipObj.style.color = "#393939";
      tipObj.style.borderRadius = "5px";
      tipObj.style.padding = "8px";
      tipObj.style.fontFamily = "Arial,Helvetica";
      tipObj.style.fontWeight = "600";
      tipObj.style.zIndex = "1000000000";
      if(findModerPop) {
        tipObj.innerHTML = 'Population: <span style="font-weight: 400">' + findModerPop.title.replaceAll('*', ' ') + '</span> <br/>Genetic Distance: <span style="font-weight: 400; color:'+ findModerPop.gradHSL +'">' + findModerPop.distance + '</span>';
      }

      //position it
      tipObj.style.position = "fixed";
      tipObj.style.top = event.originalEvent.clientY + offset.y + "px";
      tipObj.style.left = event.originalEvent.clientX + offset.x + "px";

      //add it to the body
      document.body.appendChild(tipObj);
      
      event.target.setStyle({ weight: 2 });
    }

    function moveTooltip(event) {
        if (tipObj && event) {
            //position it
            tipObj.style.top = event.originalEvent.clientY + offset.y + "px";
            tipObj.style.left = event.originalEvent.clientX + offset.x + "px";
        }
    }

    function mouseOutOfRegion(event) {
      event.target.feature.properties.isHover = false;
      event.target.setStyle({ weight: 0.5 });
      if (tipObj) {
        //delete the tooltip if it exists in the DOM
        document.body.removeChild(tipObj);
        tipObj = null;
      }
    }
  },
}

</script>
