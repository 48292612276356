<template>
  <div :class="className">
    <svg :id="$store.state.appConfig.layout.skin" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.99 29.99">
      <defs>
      </defs>
      <g id="Layer_2-2" data-name="Layer 2">
        <path class="cls-1" d="M4.39,25.6C-1.46,19.75-1.46,10.24,4.39,4.39c5.85-5.85,15.36-5.85,21.21,0,5.85,5.85,5.85,15.36,0,21.21s-15.36,5.85-21.21,0ZM24.19,5.8C19.12,.73,10.87,.73,5.8,5.8,.73,10.87,.73,19.12,5.8,24.19c5.07,5.07,13.32,5.07,18.38,0,5.07-5.07,5.07-13.32,0-18.38Z"/>
        <path class="cls-1" d="M8.08,10.54l2.46-2.46,11.36,11.36-2.46,2.46L8.08,10.54Zm.05,8.68l11.09-11.09,2.64,2.64-11.09,11.09-2.64-2.64Z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    className: {
      default: 'normal',
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.normal {
  #dark .cls-1, #dark .cls-2 {
    fill: var(--lab-lago-blue-color);
  }
  #light .cls-1, #light .cls-2 {
    fill: var(--lab-carbon-fiber);
  }
}

.encyclopedia {
  #dark .cls-1, #dark .cls-2 {
    fill: var(--lab-g25-supplementary-color);
  }
  #light .cls-1, #light .cls-2 {
    fill: var(--lab-stieglitz-silver);
  }
}
</style>
