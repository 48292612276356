import { render, staticRenderFns } from "./PeriodicalRegions.vue?vue&type=template&id=11ce3eec&scoped=true&"
import script from "./PeriodicalRegions.vue?vue&type=script&lang=js&"
export * from "./PeriodicalRegions.vue?vue&type=script&lang=js&"
import style0 from "./PeriodicalRegions.vue?vue&type=style&index=0&id=11ce3eec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ce3eec",
  null
  
)

export default component.exports