<template>
  <div class="help-container">
    <button class="lab-btn lab-btn-help" @click="toggleHelp();">
      <help-icon />  Help
    </button>

    <div class="help-body" :class="{ 'show' : isOpenHelp}">
      <h5>? Help</h5>
      <hr />
      <div class="help-content">
        <p>
          DIY section allows you to make your
          own models and experiments. 
        </p>
        <p>
          1. Select the populations of interest.
          Using all populations with ‘unlimited’
          option is usually not recommended.
        </p>
        <p>
          2. Adjust calculation limit (find the 
          best fitting 2, 3 etc. populations 
          amongst the selected populations 
          or use all)
        </p>
        <p>
          3. Press the ‘calculate’ button 
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from '@/layouts/icons/HelpIcon.vue'

export default {
  components: {
    HelpIcon,
  },
  props: {
  },
  data() {
    return {
      isOpenHelp: false,
    }
  },
  methods: {
    toggleHelp() {
      this.isOpenHelp = !this.isOpenHelp;
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>

</style>
