<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="data-selection"
      title="Filter Populations"
      cancel-variant="outline-secondary"
      @hidden="hiddenModal"
      size="lg"
      content-class="diy-modal"
    >
      <b-row class="p-2">
        <b-col sm="6" md="6">
          <label class="lab-checkbox-container">
            <span class="label"> {{ 'Select All' }}</span>
            <input type="checkbox" v-model="isActiveAll" @change="onToggleActive()">
            <span class="checkmark"></span>
          </label>
        </b-col>
        <b-col sm="6" md="6">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1 diy-form"
            size="sm"
            placeholder="Search..."
          />
        </b-col>
      </b-row>
      
      <div class="select-data-card cs-scroll-style">
        <div class="pop-item" :class="{'selected': item.isActive}"
          v-for="item in filteredSourceData" :key="item.sourceCode">

        <label class="lab-checkbox-container">
          <span class="label"> {{item.sourceCode.replaceAll('*', ' ') }}</span>
          <input type="checkbox" v-model="item.isActive">
          <span class="checkmark"></span>
        </label>
        </div>
      </div>

      <template #modal-footer>
        <!-- <b-button variant="secondary" class="float-right" @click="hiddenModal">
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          {{ $t("Close") }}
        </b-button> -->
        <button class="lab-btn float-right lab-btn-diy" @click="handleOk">
          {{ $t("Save") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
      sourceDatas: [],
      searchQuery: '',
      isActiveAll: true,
    }
  },
  methods: {
    onToggleActive() {
      this.sourceDatas.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },
    handleOk() {
      this.$store.state.customancestrybreakdown.customAncBrDnSourceDataActive = this.sourceDatas;
      this.hiddenModal();
    },
    initialModal() {
      this.sourceDatas = this.$store.state.customancestrybreakdown.customAncBrDnSourceDataActive.map(item => item);
      this.$refs['data-selection'].show();
    },
    hiddenModal() {
      this.$refs['data-selection'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    filteredSourceData() {
      return this.sourceDatas.filter(item => item.sourceCode.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="css" scoped>
.lab-checkbox-container input:checked ~ .checkmark {
  background-color: var(--lab-g25-diy-color) !important;
}
</style>

<style lang="scss">
.diy-form:focus {
  border: 2px solid var(--lab-g25-diy-color) !important;
}

.diy-modal {
  .modal-header .close {
    background-color: #a8739040 !important;
    color: var(--lab-g25-diy-color) !important;
  }
  .modal-header::before {
    background-color: var(--lab-g25-diy-color) !important;
  }
  .title-header::before {
    background-color: var(--lab-g25-diy-color) !important;
  }
}
.dark-layout .diy-modal {
  .modal-header h5 {
    color: var(--lab-g25-diy-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-diy-color) !important;
  }
}
</style>
