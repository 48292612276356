<template>
  <div id="ancient-breakdown-map">
    <l-map ref="breakdownMap" :options="{ attributionControl: false }"
      :zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom" :center="center">
      <l-tile-layer :url="mapStyleUrl" :zIndex="zIndex"></l-tile-layer>
      <l-geo-json 
        v-for="item in geoJsonList"
        :key="item.sourceCode"
        :geojson="item.geoData" 
        :optionsStyle="item.geoJsonStyle"
        :options="item.options"
      >
      </l-geo-json>
    </l-map>
    <GeoMapDetailModal v-if="modalObject.showModal" :modalObject="modalObject"/>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import GeoMapDetailModal from './GeoMapDetailModal.vue';
import ancientMapHelper from './ancientMapHelper'

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    GeoMapDetailModal,
  },
  props: {
    geoList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      zoom: 2,
      maxZoom: 6,
      minZoom: 2,
      zIndex: 4,
      center: [44.312223, 30.046882],
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      modalObject: {
        item: null,
        showModal: false,
      },
    }
  },
  computed: {
    geoJsonList() {
      /* eslint-disable*/
      let tempModalObject = this.modalObject;
      const geoList = this.geoList.map(item => {
        if(typeof item.geoData == 'string')
          item.geoData = JSON.parse(item.geoData);
        
        let opacity = parseFloat(item.ancestryValue) / 100;
        opacity = opacity === 0 ? 0.30 : (opacity > 0.5 ? 0.5 : (opacity < 0.07 ? 0.07 : opacity));
        item.geoJsonStyle = {
          fillColor: item.color,
          color: item.color,
          weight: 0,
          fillOpacity: opacity,
        };

        item.options = {
          onEachFeature: function(feature, layer) {
            if (feature.properties) {
              layer.on('mouseover', function(e) { 
                ancientMapHelper.injectTooltip(e, item);
              });
              layer.on('mousemove', function(e) { 
                ancientMapHelper.moveTooltip(e);
              });
              layer.on('mouseout', function() {  
                ancientMapHelper.deleteTooltip(); 
              });
              layer.on('click', function() {  
                // onClictItem();
                tempModalObject.item = item; 
                tempModalObject.showModal = true; 
              });
            }
          },
        };

        return item;
      });

      return geoList;
    },
    mapStyleUrl() {
      return this.skin === 'light' ? 'https://api.mapbox.com/styles/v1/ozenkaraocak/cl3r3gh12000c14nym9fgjwve/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2ajFocjBkcXIzb21wb250dGFuZXEifQ.A9TP4DXt4G4zYafoO7j5GQ'
        : 'https://api.mapbox.com/styles/v1/ozenkaraocak/cls68a1jk00pd01qq04jxf0y6/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2ajFocjBkcXIzb21wb250dGFuZXEifQ.A9TP4DXt4G4zYafoO7j5GQ';
    },
  },
  setup() { 
    const { skin } = useAppConfig();
    return { skin };
  },
}

</script>

<style lang="scss" scoped>
#ancient-breakdown-map {
  height: 600px;
  @media screen and (max-width: 1500px) {
    height: 500px !important;
  }
}
</style>
