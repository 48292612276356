<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <rect class="cls-2" x="1" y="1" width="40" height="40" rx="6.02" ry="6.02"/>
        <path class="cls-1" d="M8.45,12.22c0-2.08,1.69-3.76,3.76-3.76H29.78c2.08,0,3.76,1.69,3.76,3.76v1.47c0,1-.4,1.96-1.1,2.66l-7.31,7.31c-.24,.24-.37,.55-.37,.89v2.94c0,1-.4,1.96-1.1,2.66l-2.76,2.76c-1.35,1.35-3.66,.39-3.66-1.52v-6.85c0-.33-.13-.65-.37-.89l-7.31-7.31c-.71-.71-1.1-1.66-1.1-2.66v-1.47Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>        
.cls-1 {
  fill: #e27d95;
  fill-rule: evenodd;
}

.cls-2 {
  fill: none;
  stroke: #e27d95;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
</style>
