<template>
  <div>
    <div class="modern-mixedmode-card">
      <div class="modern-mixedmode-item">
        <div class="modern-left-side">
          <div class="text-avatar" :style="{color: '#67d2e1', }">
            {{wayObject.firstPop[1][0]}}
          </div>
          <div class="modern-text-progress ml-2">
            <div class="text">{{ wayObject.firstPop[1].replaceAll('*', ' ') }}</div>
            <div class="progress" :style="{ backgroundColor: ddAlpha('#67d2e1', 0.1) , width: '100%' }">
              <div class="progress-fill" :style="{ backgroundColor: '#67d2e1', width: wayObject.firstPop[0] + '%' }"></div>
            </div>
          </div>
        </div>

        <div class="modern-pop">
          {{ wayObject.firstPop[0] }}%
        </div>
      </div>
      <div class="modern-mixedmode-item">
        <div class="modern-left-side">
          <div class="text-avatar" :style="{color: '#ffb129', }">
            {{wayObject.secondPop[1][0]}}
          </div>
          <div class="modern-text-progress ml-2">
            <div class="text">{{ wayObject.secondPop[1].replaceAll('*', ' ') }}</div>
            <div class="progress" :style="{ backgroundColor: ddAlpha('#ffb129', 0.1) , width: '100%' }">
              <div class="progress-fill" :style="{ backgroundColor: '#ffb129', width: wayObject.secondPop[0] + '%' }"></div>
            </div>
          </div>
        </div>

        <div class="modern-pop">
          {{ wayObject.secondPop[0] }}%
        </div>
      </div>

      <div class="modern-mixedmode-item" v-if="wayObject.thirdPop">
        <div class="modern-left-side">
          <div class="text-avatar" :style="{color: '#e27d95', }">
            {{wayObject.thirdPop[1][0]}}
          </div>
          <div class="modern-text-progress ml-2">
            <div class="text">{{ wayObject.thirdPop[1].replaceAll('*', ' ') }}</div>
            <div class="progress" :style="{ backgroundColor: ddAlpha('#e27d95', 0.1) , width: '100%' }">
              <div class="progress-fill" :style="{ backgroundColor: '#e27d95', width: wayObject.thirdPop[0] + '%' }"></div>
            </div>
          </div>
        </div>

        <div class="modern-pop">
          {{ wayObject.thirdPop[0] }}%
        </div>
      </div>
      <hr />
    </div>

  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    wayObject: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: '#00afb9',
    },
  },
  data() {
    return {
      modalObject: {
        item: {
          title: '',
          id: null,
        },
        showModal: false,
        isMixedMode: true,
      },
    }
  },
  methods: {
    openAncientDetail(id, title) {
      if (id) {
        this.modalObject.item.id = id;
        this.modalObject.item.title = title;
        this.modalObject.showModal = true;
      }
    },
    ddAlpha(color, opacity) {
      const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
}
</script>

<style lang="scss" scoped>
.modern-mixedmode-card {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;

  .modern-mixedmode-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .modern-left-side {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .text-avatar {
        font-size: 1.5rem;
        font-weight: bold;
        width: 25px;
      }
      .modern-text-progress {
        width:100%;
      }
      .text {
        font-weight: bold;
        font-size: 0.8rem;
        color: var(--lab-text-color-title-02);
        margin-bottom: 5px;
      }
      .progress {
        margin-top: 10px;
        height: 10px;
        border-radius: 15px;
        .progress-fill {
          height: 10px;
          border-radius: 15px;
        }
      }
    }

    .modern-pop {
      width: 50px;
      height: 40px;
      align-items: end;
      justify-content: end;
      display: flex;
      font-weight: bold;
      font-size: 0.8rem;
      color: var(--lab-text-color-title-02);
    }
  }

}
</style>
