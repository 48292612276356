<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.08 27.26">
    <defs>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <circle class="cls-1" cx="13.04" cy="13.04" r="13.04"/>
        <text class="cls-2" transform="translate(7.06 21.43)"><tspan x="0" y="0">?</tspan></text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>        
svg {
  height: 25px;
}
.cls-1 {
  fill: #fff;
}

.cls-2 {
  fill: #afafaf;
  font-family: Montserrat-SemiBold, Montserrat;
  font-size: 22.01px;
}
</style>
