<template>
  <div>
    <div class="ancient-mixedmode-item" @click="openAncientDetail(wayObject.id1, wayObject.firstPop[1])">
      <div class="ancient-left-side">
        <b-media-aside class="mr-auto ancient-img">
          <b-avatar
            rounded
            size="70"
            :src="wayObject.pictureUrl1"
          />
        </b-media-aside>
        <div class="ancient-text-progress ml-2">
          <div class="text">{{ wayObject.firstPop[1].replaceAll('*', ' ') }}</div>
          <div class="progress" :style="{ backgroundColor: ddAlpha('#67d2e1', 0.1) , width: '100%' }">
            <div class="progress-fill" :style="{ backgroundColor: '#67d2e1', width: wayObject.firstPop[0] + '%' }"></div>
          </div>
        </div>
      </div>

      <div class="ancient-pop">
        {{ wayObject.firstPop[0] }}%
      </div>
    </div>

    <div class="ancient-mixedmode-item" @click="openAncientDetail(wayObject.id2, wayObject.secondPop[1])">
      <div class="ancient-left-side">
        <b-media-aside class="mr-auto">
          <b-avatar
            rounded
            size="70"
            :src="wayObject.pictureUrl2"
          />
        </b-media-aside>
        <div class="ancient-text-progress ml-2">
          <div class="text">{{ wayObject.secondPop[1].replaceAll('*', ' ') }}</div>
          <div class="progress" :style="{ backgroundColor: ddAlpha('#ffb129', 0.1) , width: '100%' }">
            <div class="progress-fill" :style="{ backgroundColor: '#ffb129', width: wayObject.secondPop[0] + '%' }"></div>
          </div>
        </div>
      </div>

      <div class="ancient-pop">
        {{ wayObject.secondPop[0] }}%
      </div>
    </div>
    <div class="ancient-mixedmode-item" @click="openAncientDetail(wayObject.id3, wayObject.thirdPop[1])" v-if="wayObject.thirdPop">
      <div class="ancient-left-side">
        <b-media-aside class="mr-auto">
          <b-avatar
            rounded
            size="70"
            :src="wayObject.pictureUrl3"
          />
        </b-media-aside>
        <div class="ancient-text-progress ml-2">
          <div class="text">{{ wayObject.thirdPop[1].replaceAll('*', ' ') }}</div>
          <div class="progress" :style="{ backgroundColor: ddAlpha('#e27d95', 0.1) , width: '100%' }">
            <div class="progress-fill" :style="{ backgroundColor: '#e27d95', width: wayObject.thirdPop[0] + '%' }"></div>
          </div>
        </div>
      </div>

      <div class="ancient-pop">
        {{ wayObject.thirdPop[0] }}%
      </div>
    </div>

    <AncientDetailPage v-if="modalObject.showModal" :modalObject="modalObject" :variant="'mixed-mode-ancient'"/>
  </div>
</template>

<script>
import AncientDetailPage from '../../../pages/AncientDetailPage.vue';

export default {
  components: {
    AncientDetailPage,
  },
  props: {
    wayObject: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: '#00afb9',
    },
  },
  data() {
    return {
      modalObject: {
        item: {
          title: '',
          id: null,
        },
        showModal: false,
        isMixedMode: true,
      },
    }
  },
  methods: {
    openAncientDetail(id, title) {
      if (id) {
        this.modalObject.item.id = id;
        this.modalObject.item.title = title;
        this.modalObject.showModal = true;
      }
    },
    ddAlpha(color, opacity) {
      const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
}
</script>

<style lang="scss" scoped>
.ancient-mixedmode-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;

  .ancient-left-side {
    display: flex;
    align-items: center;
    width: 90%;

    .ancient-text-progress {
      width:100%;
    }
    .text {
      font-weight: bold;
      font-size: 0.8rem;
      color: var(--lab-text-color-title-02);
      margin-bottom: 5px;
    }
    .text:hover {
      color: var(--lab-g25-unsupervised-color);
    }
    .progress {
      margin-top: 10px;
      height: 10px;
      border-radius: 15px;
      .progress-fill {
        height: 10px;
        border-radius: 15px;
      }
    }
  }

  .b-avatar {
    transition: transform .35s !important;
  }
  .b-avatar:hover {
    transform: scale(1.25) !important;
  }

  .ancient-pop {
    width: 50px;
    height: 40px;
    align-items: end;
    justify-content: end;
    display: flex;
    font-weight: bold;
    font-size: 0.8rem;
    color: var(--lab-text-color-title-02);
  }
}
</style>
