<template>
    <ol>
        <li>Select the populations of interest</li>
        <li>Select the restriction limit</li>
        <li>Hit the "calculate" button</li>
    </ol>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
ol {
  padding-left: 0px;
  counter-reset: item;
}

li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--lab-carbon-fiber);
  position: relative;
  padding-left: 20px;
  list-style: none;
}

li:before {
  font-size: 1rem;
  content: counter(item) ".";
  counter-increment: item;
  position: absolute;
  left: 0;
  color: var(--lab-g25-diy-color); /* Numaraların rengi */
  font-weight: bold;
}

.dark-layout {
  li {
    color: var(--lab-white);
  }
}
</style>
