<template>
  <div class="d-flex align-items-center justify-content-start">
    <div class="lab-timeline">
      <div class="line-item" 
        :class="{'done': periodItem.number <= $store.state.operation.selectedAncientPeriod.number,
                'active': periodItem.id == $store.state.operation.selectedAncientPeriod.id}"
        v-for="periodItem in $store.state.operation.ancientPeriods" :key="periodItem.id" >
          <div class="node">
            <span class="timeline-title top-title" v-if="periodItem.number%2 === 1">{{periodItem.name}}</span>
            <span class="timeline-title sub-title" v-else>{{periodItem.name}}</span>
            <div class="tvar" @click="onChangePeriod(periodItem)"></div>
          </div>
      </div>
    </div>

    <div class="lab-timeline-switch">
      <div class="timeline-item" @click="switchLeft()">
        <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_left.svg'" />
      </div>
      <div class="timeline-item last"  @click="switchRight()">
        <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_right.svg'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    runAncestryBreakDown: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    onChangePeriod(periodItem) {
      this.$store.commit('operation/setAncestryGeoJson', []);
      this.$store.commit('operation/setSelectedPeriod', periodItem);
      
      this.runAncestryBreakDown();
    },

    switchRight() {
      const findNext = this.$store.state.operation.ancientPeriods.find(x => x.number === this.$store.state.operation.selectedAncientPeriod.number + 1);
      if (findNext) {
        this.onChangePeriod(findNext);
      }
    },
    switchLeft() {
      const findNext = this.$store.state.operation.ancientPeriods.find(x => x.number === this.$store.state.operation.selectedAncientPeriod.number - 1);
      if (findNext) {
        this.onChangePeriod(findNext);
      }
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
.done .tvar, .dark-layout .done .tvar {
  background-color: var(--lab-g25-supervised-color) !important;
}
.line-item.done:after, .line-item.done:after {
  background-color:  var(--lab-g25-supervised-color) !important;
}
.line-item.done:before, .line-item.done:before {
  background-color: var(--lab-g25-supervised-color) !important;;
}
.line-item.done.active:after, .line-item.done.active:after {
  background-color:  var(--lab-explosive-grey) !important;
}
</style>
