<template>
  <div style="height: 600px;">
    <l-map ref="breakdownMap" :options="{ attributionControl: false }"
      :zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom" :center="center">
      <l-tile-layer :url="mapStyleUrl" :zIndex="zIndex"></l-tile-layer>
      <l-circle-marker v-for="(group, index) in circleGroups" :key="index"
        :className="group.id"
        :lat-lng="group.center"
        :radius="group.radius"
        :fillColor="group.color"
        :color="'#000'"
        :fillOpacity="1"
        :weight="1"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        @mousemove="mouseMove"
        @click="spiderfy"
      />

      <LCircleMarker
        v-for="(marker, index) in spiderfiedMarkers"
        :key="'spiderfied-' + index"
        :className="marker.id"
        :lat-lng="[marker.lat, marker.lng]"
        :radius="marker.radius"
        :fillColor="marker.color"
        :color="'#000'"
        :fillOpacity="1"
        :weight="1"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        @mousemove="mouseMove"
        @click="mouseEnter"
      ></LCircleMarker>
      <LPolyline
        v-for="(line, index) in spiderfiedLines"
        :key="'line-' + index"
        :lat-lngs="line"
        :weight="2"
        color="#e27d95"
      ></LPolyline>
    </l-map>
    <AncientDetailPage v-if="modalObject.showModal" :modalObject="modalObject" :variant="'genetic-dist-ancient'"/>
  </div>
</template>

<script>
import { LMap, LTileLayer, LCircleMarker, LPolyline } from "vue2-leaflet";
import AncientDetailPage from '../../../pages/AncientDetailPage.vue';

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    LPolyline,
    AncientDetailPage,
  },
  props: {
  },
  data() {
    return {
      zoom: 2,
      maxZoom: 10,
      minZoom: 2,
      zIndex: 1,
      center: [44.312223, 30.046882],
 
      circleGroups: [],
      spiderfiedMarkers: [],
      spiderfiedLines: [],
      openedGroupId: '',
      
      modalObject: {
        item: {
          title: '',
          id: null,
        },
        showModal: false,
        isMixedMode: false,
      },
      tipObj: null,
      offset: {
        x: 5,
        y: -75,
      },
    }
  },
  computed: {
    mapStyleUrl() {
      return this.skin === 'light' ? 'https://api.mapbox.com/styles/v1/ozenkaraocak/clski0yqq00h301qx4jbefjq9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2ajFocjBkcXIzb21wb250dGFuZXEifQ.A9TP4DXt4G4zYafoO7j5GQ'
        : 'https://api.mapbox.com/styles/v1/ozenkaraocak/cls68a1jk00pd01qq04jxf0y6/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3plbmthcmFvY2FrIiwiYSI6ImNsZDR2ajFocjBkcXIzb21wb250dGFuZXEifQ.A9TP4DXt4G4zYafoO7j5GQ';
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
    ancientPopData() {
      return this.$store.getters['ancientpopulation/getClosestAncientPopImageResults'];
    },
  },
  methods: {
    mouseOver(event) {
      /* eslint-disable*/
      const targets = event.target.options.className.split('_');
      const findAncientPop = this.ancientPopData.find(popValue => targets[0] === popValue.id + '' );
      
      this.tipObj = document.createElement("div");
      this.tipObj.style.background = "white";
      this.tipObj.style.color = "#393939";
      this.tipObj.style.borderRadius = "5px";
      this.tipObj.style.padding = "8px";
      this.tipObj.style.fontFamily = "Arial,Helvetica";
      this.tipObj.style.fontWeight = "600";
      this.tipObj.style.zIndex = "1000000000";
      if(findAncientPop) {
        this.tipObj.innerHTML = 'Population: <span style="font-weight: 400">' + findAncientPop.title.replaceAll('*', ' ') + '</span>' +
                                '<br/>Sample: <span style="font-weight: 400;">' + targets[1] + '</span>'+
                                '<br/>Genetic Distance: <span style="font-weight: 400; color:'+ findAncientPop.gradHSL +'">' + findAncientPop.distance + '</span>';
                                
      }

      //position it
      this.tipObj.style.position = "fixed";
      this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
      this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";

      //add it to the body
      document.body.appendChild(this.tipObj);
      
      event.target.setStyle({ weight: 2 });
    },
    mouseOut(event) {
      event.target.setStyle({ weight: 0.5 });
      if (this.tipObj) {
        //delete the tooltip if it exists in the DOM
        document.body.removeChild(this.tipObj);
        this.tipObj = null;
      }
    },
    mouseMove(event) {
      if (this.tipObj && event) {
        this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
        this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";
      }
    },
    mouseEnter(event) {
      const targets = event.target.options.className.split('_');
      const findAncientPop = this.ancientPopData.find(popValue => targets[0] === popValue.id + '' );

      this.modalObject.item.id = findAncientPop.id;
      this.modalObject.item.title = findAncientPop.title;
      this.modalObject.showModal = true;

      if (this.tipObj) {
        document.body.removeChild(this.tipObj);
        this.tipObj = null;
        this.spiderfiedLines = [];
        this.spiderfiedMarkers = [];
        this.openedGroupId = '';
      }
    },
    spiderfy(event) {
      const group = this.circleGroups.find(x => x.id === event.target.options.className);
      if (group.markers.length === 1) {
        this.mouseEnter(event);
        return;
      }

      if (this.openedGroupId === group.id) {
        this.spiderfiedLines = [];
        this.spiderfiedMarkers = [];
        this.openedGroupId = '';
        return;
      }
      
      this.spiderfiedMarkers = [];
      this.spiderfiedLines = [];
      
      const center = group.center;
      const angleStep = (2 * Math.PI) / group.markers.length;
      const distance = 1; // Distance from the original point

      group.markers.forEach((marker, index) => {
        const angle = index * angleStep;
        const latOffset = distance * Math.cos(angle);
        const lngOffset = distance * Math.sin(angle);

        const spiderfiedMarker = {
          lat: center[0] + latOffset,
          lng: center[1] + lngOffset,
          ...marker,
        };

        this.spiderfiedMarkers.push(spiderfiedMarker);
        this.spiderfiedLines.push([[center[0], center[1]], [spiderfiedMarker.lat, spiderfiedMarker.lng]]);
      });
      this.openedGroupId = group.id;
    },

    onLoadSampleCoordinates() {
      const ancientProxDataIds = this.ancientPopData.map(x => x.id);
      this.$store.dispatch('ancientpopulation/fetchSampelCoordinates', { ancientProxDataIds })
        .then(response => {
          if (response.statusCode === 200) {
            response.result.forEach(item => {
              if (item.latitude && item.longitude) {
                const center = this.offsetCoordinates(Number(item.latitude), Number(item.longitude), 0.001);
                const ancientProxData = this.ancientPopData.find(x => x.id === item.ancientProxDataId);
                const circleGroup = this.circleGroups.find(x => x.center[0] === center[0] && x.center[1] === center[1]);
                
                if(circleGroup) {
                  circleGroup.markers.push({
                    id: `${item.ancientProxDataId}_${item.sample}`,
                    radius: 6,
                    color: ancientProxData.gradHSL,
                    opacity: this.calculateOpacity(ancientProxData.distance),
                  });
                } else {
                  this.circleGroups.push({
                    id: `${item.ancientProxDataId}_${item.sample}`,
                    center: center,
                    radius: 6,
                    color: ancientProxData.gradHSL,
                    opacity: this.calculateOpacity(ancientProxData.distance),
                    markers: [{
                      id: `${item.ancientProxDataId}_${item.sample}`,
                      radius: 6,
                      color: ancientProxData.gradHSL,
                      opacity: this.calculateOpacity(ancientProxData.distance),
                    }]
                  });
                }
              }

              this.circleGroups.sort((a, b) => a.opacity - b.opacity);
            });
          }
        });
    },
    
    calculateOpacity(distance) {
      const maxValue = Math.max(...this.ancientPopData.map(o => o.distance));
     
      const opacity = 1-(((distance * 100) / maxValue)/100);
      return opacity;
    },
    offsetCoordinates(lat, lng, offset) {
      return [lat, lng];
    },
  },
  mounted() {
    /* eslint-disable*/
    this.onLoadSampleCoordinates();
  },
}

</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
