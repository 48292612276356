<template>
  <div>
    <periodical v-if="isLoad && G25_RESULT_TABS.PERIODICAL.code === selectedResultType" />
    <hg-farmer v-if="isLoad && G25_RESULT_TABS.FARMER_HGS.code === selectedResultType" />
    <mixed-mode-ancient v-if="isLoad && G25_RESULT_TABS.MIXED_MODE.code === selectedResultType"/>
    <mixed-mode-modern v-if="isLoad && G25_RESULT_TABS.MODERN_MIXED_MODE.code === selectedResultType"/>

    <modern-genetic-distance v-if="isLoad && G25_RESULT_TABS.MODERN_GENETIC_DISTANCES.code === selectedResultType"/>
    <ancient-genetic-distance v-if="isLoad && G25_RESULT_TABS.ANCESTRY_GENETIC_DISTANCES.code === selectedResultType"/>
    <pca-plot v-if="isLoad && G25_RESULT_TABS.PLOT_PCA.code === selectedResultType"/>

    <modern-diy-model v-if="isLoad && G25_RESULT_TABS.MODERN_DIY_MODEL.code === selectedResultType"/>
    <ancient-diy-model v-if="isLoad && G25_RESULT_TABS.ANCIENT_DIY_MODEL.code === selectedResultType"/>

    <encyclopedia v-if="isLoad && G25_RESULT_TABS.ENCYCLOPEDIA.code === selectedResultType"/>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { G25_RESULT_TABS } from '@/common/config'

import Periodical from './g25-models/supervised/Periodical.vue'
import HgFarmer from './g25-models/supervised/HgFarmer.vue'

import MixedModeAncient from './g25-models/upsupervised/MixedModeAncient.vue'
import MixedModeModern from './g25-models/upsupervised/MixedModeModern.vue'

import ModernGeneticDistance from './g25-models/genetic-distances/ModernGeneticDistance.vue'
import AncientGeneticDistance from './g25-models/genetic-distances/AncientGeneticDistance.vue'
import PcaPlot from './g25-models/pca/PcaPlot.vue'

import ModernDiyModel from './g25-models/diy/ModernDiyModel.vue'
import AncientDiyModel from './g25-models/diy/AncientDiyModel.vue'

import Encyclopedia from './g25-models/supplementary/Encyclopedia.vue'

export default {
  components: {
    Periodical,
    HgFarmer,
    MixedModeAncient,
    MixedModeModern,
    ModernGeneticDistance,
    AncientGeneticDistance,
    PcaPlot,
    ModernDiyModel,
    AncientDiyModel,
    Encyclopedia,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoad: true,
      G25_RESULT_TABS,
      selectedResultType: G25_RESULT_TABS.PERIODICAL.code,
    }
  },
  methods: {
    onInitResultTabs() {
      if (this.selectedResult.orderResultStatus <= 1) {
        this.$router.push({ name: 'dashboard-g25' });
      }

      if (this.selectedResultType === G25_RESULT_TABS.PERIODICAL.code) {
        setTimeout(() => {
          if (this.$root.$refs.Periodical) {
            this.$store.commit('ancestrybreakdown/setRegionByTarget');
            this.$store.commit('operation/setAncestryGeoJson', []);

            if (this.$store.state.operation.ancientPeriods.length) {
              this.$root.$refs.Periodical.runAncestryBreakDown();
            } else {
              this.$store.dispatch('operation/fetchAncientPeriods', {})
                .then(() => {
                  this.$root.$refs.Periodical.runAncestryBreakDown();
                })
            }
          }
        });
      }
      if (this.selectedResultType === G25_RESULT_TABS.FARMER_HGS.code) {
        setTimeout(() => { 
          if (this.$root.$refs.FarmerAndHGs) {
            this.$root.$refs.FarmerAndHGs.onChangeFarmerAndHGs();
          }
        });
      }

      if (this.selectedResultType === G25_RESULT_TABS.MIXED_MODE.code) {
        setTimeout(() => { 
          if (this.$root.$refs.MixedModeAncient) {
            this.$root.$refs.MixedModeAncient.Initialize();
          }
        });
      }
      if (this.selectedResultType === G25_RESULT_TABS.MODERN_MIXED_MODE.code) {
        setTimeout(() => { 
          if (this.$root.$refs.MixedModeModern) {
            this.$root.$refs.MixedModeModern.Initialize();
          }
        });
      }

      if (this.selectedResultType === G25_RESULT_TABS.MODERN_GENETIC_DISTANCES.code) {
        setTimeout(() => { 
          if (this.$root.$refs.ModernGeneticDistance) {
            this.$root.$refs.ModernGeneticDistance.onChangeModernPop();
          }
        });
      }
      if (this.selectedResultType === G25_RESULT_TABS.ANCESTRY_GENETIC_DISTANCES.code) {
        setTimeout(() => { 
          if (this.$root.$refs.AncientGeneticDistance) {
            this.$root.$refs.AncientGeneticDistance.onChangeClosestAncientPop();
          }
        });
      }

      if (this.selectedResultType === G25_RESULT_TABS.PLOT_PCA.code) {
        setTimeout(() => { 
          if (this.$root.$refs.PcaPlot) {
            this.$root.$refs.PcaPlot.onSetRegion(this.selectedResult.pcaRegionId);
            this.$root.$refs.PcaPlot.onSetRegionGrup(this.selectedResult.pcaRegionGrupId, true);
            this.$root.$refs.PcaPlot.onChangePcaRegion();
          }
        });
      }

      if (this.selectedResultType === G25_RESULT_TABS.MODERN_DIY_MODEL.code) {
        setTimeout(() => {
          if (this.$root.$refs.ModernDiyModel) {
            this.$root.$refs.ModernDiyModel.onChangeModernBreakDown(true);
          }
        });
      }
      if (this.selectedResultType === G25_RESULT_TABS.ANCIENT_DIY_MODEL.code) {
        setTimeout(() => {
          if (this.$root.$refs.AncientDiyModel) {
            this.$store.commit('operation/setAncestryGeoJson', []);
            this.$root.$refs.AncientDiyModel.onRun(true);
          }
        });
      }
    },
  },
  computed: {
    selectedResultId() {
      return this.$store.getters['orderResult/getSelectedOrderResultId'];
    },
    selectedResult() {
      return this.$store.getters['orderResult/getSelectedOrderResult'];
    },
  },
  watch: {
    selectedResultId(newValue, oldValue) {
      this.onInitResultTabs();
    },
  },
  created() {
    this.selectedResultType = this.$router.currentRoute.params.type;
    if (this.selectedResultId) {
      this.onInitResultTabs();
    }
  },
}
</script>

<style lang="css" scoped>

@media screen and (max-width: 1300px) {
  .cs-hide-mobile {
    display: none;
  }
}

@media screen and (min-width: 1300px) {
  .cs-hide-desktop {
    display: none;
  }
}

</style>
<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
