<template>
  <div class="help-container">
    <button class="lab-btn lab-btn-help" @click="toggleHelp();">
      <help-icon />  Help
    </button>

    <div class="help-body" :class="{ 'show' : isOpenHelp}">
      <h5>? Help</h5>
      <hr />
      <div class="help-content">
        <p>
          The 'Supervised Periodical Breakdown' 
          section is crafted by considering the 
          ethogeographical history of 
          populations, guided by population 
          genetics studies and historical context. 
          Its purpose is to offer insights into your 
          genetic evolution by breaking it down 
          into chronological segments.
        </p>

        <p>
          A 'default' region is assigned to you 
          based on the ethnicity choice made 
          during file upload. Unless you have a 
          'recently mixed' origin, it is not advisable 
          to use the 'Global' setup. It is 
          recommended to adhere to the region(s) 
          that best represents your known 
          ethno-geographical background.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from '@/layouts/icons/HelpIcon.vue'

export default {
  components: {
    HelpIcon,
  },
  props: {
  },
  data() {
    return {
      isOpenHelp: false,
    }
  },
  methods: {
    toggleHelp() {
      this.isOpenHelp = !this.isOpenHelp;
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>

</style>
